.payment-settings {
  width: 100%;
  padding: 0;

  &__content {
    background-color: #ffffff;
    width: 100%;
    min-height: 75vh;
  }

  &__header {
    padding: 20px 20px 0 20px;
    background-color: #f0f7f8;
    width: 100%;

    &__title {
      font-size: 24px;
      line-height: 51px;
      color: #1c1b1f;
      font-weight: 700;
      margin: 0;
    }

    &__details {
      color: #1c1b1f;
    }
  }
}
.payment-method__content {
  padding: 1.25rem 1rem;
  &__title {
    line-height: 20px;
    font-size: 18px;
    color: #1c1b1f;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 1.25rem;
  }
  .payment-method__card {
    box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    margin-bottom: 12px;
    &__list-option-selected {
      border: 2px solid #49D0D4;
      background-color: #f0f7f8;
    }
    &__list-option:hover {
      background-color: #f0f7f8;
      cursor: pointer;
    }
    .ant-card-head {
      border-bottom: 0;
      min-height: 0;
      padding: 12px;
      padding-bottom: 8px;
      .ant-card-extra {
        padding-top: 0;
        padding-bottom: 0;
      }
      .ant-card-head-title {
        padding-bottom: 0;
        padding-top: 0;
      }
      .anticon {
        font-size: 20px;
        margin-top: 2px;
      }
    }
    .ant-card-body {
      padding-top: 0;
      padding-bottom: 12px;
    }
    &__tooltip {
      background: red;
      .ant-tooltip-inner {
        background: rgba(28, 27, 31, 0.9);
        border-radius: 6px;
        box-shadow: 0px 3px 6px #00000029;
        font-size: 12px;
        color: #ffffff;
      }
    }
  }
  .ant-switch:not(.ant-switch-checked) {
    background-color: #bebebe;
  }
}

.payment-methods {
  &__methods {
    padding: 20px 16px;

    &__title {
      color: #1C1B1F;
      font-size: 16px;
      font-weight: 700;
      text-transform: capitalize;
    }

    &__radio {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      padding-left: 8px;
    }

    label {
      margin-bottom: 26px;
    }
  }

  &__fees {
    border-radius: 10px;
    margin: 0 16px;
    border: 1px solid #E8E9EB;
    box-sizing: border-box;
    display: flex;
    justify-content: center;

    @media (min-width: $container-lg) {
      width: 40%;

      &.is-modal {
        width: calc(100% - 32px);
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      width: 50%;
      align-items: center;
      padding: 12px 0;

      &:nth-child(2) {
        border-left: 1px solid #E8E9EB;
      }
    }

    &__payout-fees {
      color: #46505C;
      font-size: 24px;
      font-weight: 700;

      &.invoice {
        color: #CF2626;
      }
    }

    &__payout-time {
      color: #1C1B1F;
      text-align: center;
      font-size: 16px;

      i {
        margin-left: 8px;
      }
    }
  }

  &__children {
    border-top: 1px solid #E8E9EB;
    margin-top: 24px;
    padding: 20px 16px;

    &.is-modal {
      margin-top: 68px;
    }
  }

  .ant-radio .ant-radio-inner,
  .ant-radio {
    border-radius: 50%;
    background: #ffffff;
    border-color: #49454F;
  }

  .ant-radio.ant-radio-checked .ant-radio-inner {
    border-color: #107B7F;
    background: #ffffff;
  }

  .ant-radio-inner::after {
    background: #107B7F;
    border-radius: 50%;
  }
}

.payment-methods-bank-account,
.payment-methods-credit-card,
.payment-methods-invoice {
  &__title {
    color: #1C1B1F;
    font-size: 16px;
    font-weight: 700;
    text-transform: capitalize;
    display: block;
  }

  &__description {
    color: #46505C;
    font-size: 14px;
    font-weight: 400;
  }

  &__form {
    width: 100%;

    &.is-modal {
      width: 100%;
    }

    .ant-form-item-label {
      line-height: normal;
      padding-bottom: 4px;

      label {
        &::before {
          display: none;
        }
      }
    }
  
    .ant-form-item {
      margin-top: 18px;

      input {
        padding: 18px 12px;
      }
  
      &.control {
        margin-top: 12px;
      }
    }

    .ant-form-item-control {
      text-align: center;

      .ant-typography {
        display: block;
        margin-top: -15px;
        color: #1C1B1F;
        font-size: 14px;
        font-weight: 400;
      }
    }

    @media (min-width: $container-lg) {
      width: 40%;

      &.is-modal {
        width: 100%;
      }
    }
  }

  .ant-btn-primary {
    border-radius: 10px;
    background: #165F5F;
    width: 100%;
    height: 44px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #165F5F;
  }
}

.payment-methods-bank-account {
  display: flex;
  flex-direction: column;
  position: relative;
  
  @media (min-width: $container-lg) {
    width: 40%;

    &.is-modal {
      width: 100%;
    }
  }

  &__checkbox {
    display: block;
    position: absolute;
    top: -64px;
  }

  &__logos {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 12px;

    &__rg-logo {
      background-color: #49D0D4;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__plaid-logo {
      background-color: #000000;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid #fff;
      margin-left: -5px;
    }
  }
  
  &__description {
    color: #1C1B1F;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    padding-left: 16px;
  }

  &__info {
    padding-left: 16px;
    margin-top: 16px;
    display: flex;
    flex-direction: column;

    &__title, &__description {
      color: #1C1B1F;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      text-align: left;
    }

    &__description {
      padding-left: 20px;
    }
  }

  &__user-agreement {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    width: 100%;
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;

    a {
      font-weight: 600;
      text-decoration: underline;
      color: #000;
    }
  }
}

.payment-methods-credit-card {
  #card-number, #card-expiry, #card-cvc {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 9.8px 12px;
  }

  #card-payment-element {
    margin-top: 15px;
  }

  &__cvc-expiry-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
  }
}

.payment-methods-invoice {
  
}

.add-payment-button, .add-payment-button button {
  padding-left: 0;
  color: #165F5F;
  border: none;
  
  &:disabled {
    background-color: transparent;
  }
}

.add-payment-method-modal {
  @media (max-width: $container-lg) {
    .ant-modal {
      margin: 0;
      width: 100vw !important;
      max-width: unset;
      bottom: 0px;
      top: 32px;
      overflow-x: hidden;
      overflow-y: auto;
    }

    .ant-modal-content {
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      padding-bottom: 30px;
    }
  }

  h2 {
    margin: 0px;
    padding: 0px 16px;
  }

  .ant-modal-content {
    border-radius: 20px;
  }

  .ant-modal-body {
    padding: 16px 0px;
  }
}
