.user {
  &__header,
  &__profile,
  &__properties {
    background: $white;
    margin-bottom: 2em;
    padding: 1em;
  }

  &__p_block {
    display: flex;
    flex-direction: column;

    &-permission {
      color: $theme-primary;
      user-select: none;
      &:hover {
        cursor: pointer;
      }

      &-section {
      }
    }
  }



  &__header {
    display: flex;
    flex-direction: column;
    padding: 1em;
    margin: -23px -28px 2em -28px;

    &-content {
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
      width: 98%;

      @media (max-width: $container-xsm) {
        flex-direction: column;
      }
    }

    &-name {
      display: inline-block;
      margin-left: 10px;
    }

  }

  &__cta {
    a {
      margin-right: 1em;
    }

    @media (max-width: $container-xsm) {
      margin-top: 1em;
    }
  }

  #user__form_role {

    label {
    margin: 0.5em;
    }
  }

  &__form {
    div.ant-form-item-label {
      overflow: visible;
    }
  }

  &__role {
    p {
      margin-top: 1em;
    }
  }

  &__remove {

    &-name {
      font-weight: 500 !important;
      text-align: center !important;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
    }

    &-cta {
      background: $red;
      border-color: $red;
      margin-top: 5px;

      &:hover {
        background: $red;
        border-color: $red;
        opacity: 0.9;
        color: $white !important;
      }

      &:focus {
        color: $red;
        background: $button-danger;
      }
    }
  }

  &__profile {
    &-section {
      display: flex;
      flex-direction: row;
      margin: 1em;

      @media (max-width: $container-lg) {
        flex-direction: column;
        margin: 0em;
      }

      .ant-row {
        display: flex;
        flex-direction: row;
        width: 330px;
        justify-content: flex-end;

        @media (max-width: $container-lg) {
          flex-direction: column;
          width: 100%;
          margin-bottom: .7em
        }

        input {
          width: 250px;

          @media (max-width: $container-lg) {
            flex-direction: column;
            width: 100%;
          }
        }
      }

      .ant-form-item-label {
        margin-right: 1em;

        @media (max-width: $container-lg) {
          margin-right: 0em;
          padding: 0;
        }
      }

      @media (min-width: $container-lg) {
        .ant-row:last-of-type {
          margin-left: 15em;
        }
      }
    }
  }

  #header-user-icon {
    margin-top: 0px! important;
  }

  &__modal-header {
    .ant-modal-header .ant-modal-title {
      font-size: 18px !important;
      text-align: center !important;
    }
  }

}
