$modular-scale: (
  base: 14,
  ratio: 1.333
);

@function rem($px-val) {
  $unitless-px-val: $px-val / ($px-val * 0 + 1);
  @return $unitless-px-val / map-get($modular-scale, base) * 1rem;
}

$primary-font-stack: 'Source Sans Pro', sans-serif;

$base-font-size: map-get($modular-scale, base) * 1px;

$h6-font-size: $base-font-size / map-get($modular-scale, ratio); // 10.503px
$h5-font-size: $base-font-size; // 14px
$h4-font-size: $h5-font-size * map-get($modular-scale, ratio); // 18.662px
$h3-font-size: $h4-font-size * map-get($modular-scale, ratio); // 24.876px
$h2-font-size: $h3-font-size * map-get($modular-scale, ratio); // 33.16px
$h1-font-size: $h2-font-size * map-get($modular-scale, ratio); // 44.203px

$font-size-x-large: $h1-font-size * map-get($modular-scale, ratio); // 58.922px
$font-size-xx-large: $font-size-x-large * map-get($modular-scale, ratio); // 78.543px
$font-size-xxx-large: $font-size-xx-large * map-get($modular-scale, ratio); // 104.698px

$font-weight-normal: 400;
$font-weight-medium: 600;
$font-weight-bold: 700;
$font-weight-bolder: 800;
