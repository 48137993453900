.transactions {
  width: 100%;
  padding: 0;

  &__content {
    padding: 20px 20px 0 20px;
    background-color: #ffffff;
    width: 100%;
    min-height: 75vh;

    .ant-table-wrapper {
      overflow: unset;
    }
    .ant-table {
      &.ant-table-bordered .ant-table-body > table {
        border: none;
        .ant-table-tbody {
          > tr > td:first-child {
            border-left: 1px solid #e8e8e8;
          }
        }
      }
      .ant-table-thead {
        & > tr > th {
          border-right: none;
          background-color: transparent;
          font-weight: 400;
          padding-bottom: .2125rem;
        }
      }
      .ant-table-tbody {
        & > tr > td {
          text-transform: capitalize;
        }
      }
    }
    .transactions-table-filter {
      background-color: #ffffff;
      padding-bottom: 1.25rem;
      border-bottom: 1px solid #e8e8e8;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1rem;
      flex-wrap: wrap;
      input {
        margin: 0 !important;
        padding: 0 1em 0 1em;
      }
      .ant-input-search {
        padding-top: 0;
      }
    }
  }

  .transactions__header {
    padding: 1rem;
    background-color: #f0f7f8;
    width: 100%;

    &__back-button {
      background-color: transparent;
      border: none;
      color: #2c2c2c;
      cursor: pointer;
      padding: 0;
      span {
        margin-left: 4px;
      }
    }

    &__title {
      font-size: 24px;
      line-height: 51px;
      color: #1c1b1f;
      font-weight: 700;
      margin: 0;
    }

    &__details {
      color: #1c1b1f;
      max-width: 400px;
      .capitalize {
        text-transform: capitalize;
      }
      p {
        margin-bottom: 0.25rem;
      }
    }
  }
}

.transactions-details {
  &__content {
    padding: 20px 20px 0 20px;
    background-color: #ffffff;
    width: 100%;
    min-height: 75vh;

    .ant-table {
      &.ant-table-bordered .ant-table-body > table {
        border: none;
        .ant-table-tbody {
          > tr > td:first-child {
            border-left: 1px solid #e8e8e8;
          }
        }
      }
      .ant-table-thead {
        & > tr > th {
          border-right: none;
          background-color: transparent;
          font-weight: 400;
          padding-bottom: .2125rem;
        }
      }
      .ant-table-thead {
        & > tr > th {
          background-color: transparent;
          font-weight: 400;
        }
      }
    }
  }
}
