.ant-layout-sider-children {
  border-right: 1px solid #e8e8e8;
}

.rewards {
  &__header {
    float: left;
    text-align: left;
    display: inline-block;
  }
}

.rewardsv2 {
  &.children__wrapper {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
  }

  .rewards {
    &__header {
      float: left;
      text-align: left;
      display: inline-block;

      h3 {
        margin: 0;
      }
    }

    color: #383838;
    font-family: 'Source Sans Pro';

    &__content {
      background-color: #f0f7f8;
      padding: 20px;
      border-top: 1px solid #d6d6d6;
      border-right: 1px solid #d6d6d6;
      border-bottom: 1px solid #d6d6d6;
    }

    &__hero {
      p {
        color: #2c2c2c;

        .link {
          text-decoration: underline;
          color: #0071bc;
          cursor: pointer;
        }
      }

      h2 {
        font-size: 36px;
        font-weight: bold;
        margin-bottom: 0;
      }

      &box {
        width: 150px;

        .link {
          text-decoration: underline;
        }

        &__paymentGrade {
          h3 {
            color: #976507;
          }
        }

        &__outstanding {
          h3 {
            color: #bc4b51;
          }
        }

        h3 {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 0;
        }

        h4 {
          color: #323334;
          font-size: 16px;
          font-weight: semibold;
          margin-bottom: 2px;
        }

        p {
          font-size: 16px;
        }
      }
    }
  }

  .rewards-wrapper {
    padding-top: 10px;
  }

  .rewards-wrapper .ant-table-default.ant-table-empty {
    border-bottom: 1px solid #e8e8e8;
  }

  .rewards-wrapper .ant-table-placeholder {
    border-bottom: unset;
    background: transparent;
    position: unset;
  }

  .rewards-wrapper.no-scroll .ant-table-wrapper {
    overflow: unset;
  }

  input[type='checkbox'].ant-checkbox-input {
    margin: 0;
  }

  .arrow-down {
    width: 20px;
  }

  .ant-spin-container ul {
    position: relative !important;
  }

  .rewards__table_wrapper {
    color: #2e2e2e;
    gap: 12px;

    .default-link {
      color: #0071bc;
      text-decoration: underline;
      text-align: right;
    }

    .btn-view-selected,
    .btn-view-selected:hover,
    .btn-view-selected:active {
      font-weight: 600;
      background-color: transparent;
      border-radius: 0.625rem;
      color: #165F5F;
      border-color: #165F5F;

      &:disabled {
        background: #c4c4c4;
        border-color: transparent;
        color: rgba(0, 0, 0, 0.65);
      }
    }

    .btn-mark-rewarded,
    .btn-mark-rewarded:hover,
    .btn-mark-rewarded:active {
      font-weight: 600;
      background-color: #49d0d4;
      border-radius: 0.625rem;
      border-color: transparent;
      color: #0C3636;

      &:disabled {
        background: #c4c4c4;
        border-color: transparent;
        color: rgba(0, 0, 0, 0.65);
      }
    }

    .ant-checkbox-checked .ant-checkbox-inner::after {
      left: 22%;
    }
  }

  .bold {
    font-weight: 600;
  }

  .btn {
    border-radius: 10px !important;
    text-transform: capitalize;

    &-rg {
      min-width: 120px;

      background: transparent !important;
      color: #575757 !important;
      border: 1px solid #575757 !important;
      transition: none;
      -webkit-transition: none;

      &.selected {
        background: #1e4c4a !important;
        color: #ffffff !important;
        border: 1px;
      }

      &.ant-popover-open {
        border-radius: 10px 10px 0 0 !important;
      }
    }

    &-danger {
      background: #bc4b51;
      border-color: #6c2c30;
      color: #ffffff;
    }

    &-success {
      background: #49d49f;
      border-color: #49d49f;
      color: #000000;
    }

    &-warning {
      background: #efa00b;
      border-color: #a2731b;
      color: #000000;
    }

    &-primary {
      background: #49c4d4;
      border-color: #49c4d4;
      color: #000000;
    }

    &-secondary {
      background: #d5d5d5;
      border-color: #d5d5d5;
      color: #000000;
    }
  }

  .ant-btn.btn-link {
    color: #404040;
  }

  &-search-wrapper {
    .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
      border-color: #1d4c4a;
    }
  }

  .ant-input-search {
    padding-top: 0;

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #575757;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #575757;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #575757;
    }

    input.ant-input {
      border-radius: 10px;
      border-color: #575757;
    }

    i.ant-input-search-icon {
      color: #575757;
    }
  }

  .ant-table-thead > tr > th {
    background: transparent;
    color: #6e6e6e;
    font-weight: normal;
    padding-left: 0;
    padding-right: 0;
  }

  .ant-table-tbody > tr > td {
    padding-left: 0;
    padding-right: 0;
  }

  .ant-table-thead
    > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(
      .ant-table-row-selected
    )
    > td,
  .ant-table-tbody
    > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(
      .ant-table-row-selected
    )
    > td,
  .ant-table-thead
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td,
  .ant-table-tbody
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td {
    background: #f5f5f5;
    cursor: pointer;

    .btn.btn-link,
    .btn.btn-link:hover {
      background: #f5f5f5;
      color: initial;
    }
  }

  .ant-table {
    .rewardsTableProspectColumn {
      padding-right: 10px !important;

      strong {
        display: block;
      }

      .ant-table-column-title {
        font-weight: normal;
      }
    }

    .rewardsTableStatusColumn {
      padding-left: 10px;

      .btn {
        padding: 4px 15px;
        font-size: 14px;

        &.btn-link {
          border: 0;
          background: #ffffff;
        }
      }
    }

    .rewardsTableAmountColumn {
      padding-right: 10px;
    }

    .ant-table-selection-column {
      text-align: center;
    }

    &-wrapper {
      .ant-pagination-item,
      .ant-pagination-prev .ant-pagination-item-link,
      .ant-pagination-next .ant-pagination-item-link {
        border-radius: 10px;
      }
    }
  }

  .ant-table-wrapper {
    overflow: unset;
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: #1d4c4a;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background: #1d4c4a;
  }

  .ant-table-row-selected {
    cursor: pointer;

    td {
      background: inherit !important;
    }
  }

  color: #383838;
  font-family: 'Source Sans Pro';

  &__content {
    background-color: #f0f7f8;
    padding: rem(20px);
    border-top: 1px solid #d6d6d6;
    border-right: 1px solid #d6d6d6;
    border-bottom: 1px solid #d6d6d6;
  }

  &__hero {
    p {
      color: #2c2c2c;

      .link {
        text-decoration: underline;
        color: #0071bc;
        cursor: pointer;
      }
    }

    h2 {
      font-size: rem(36px);
      font-weight: bold;
      margin-bottom: 0;
    }

    &box {
      width: 150px;

      .link {
        text-decoration: underline;
      }

      &__paymentGrade {
        h3 {
          color: #976507;
        }
      }

      &__outstanding {
        h3 {
          color: #bc4b51;
        }
      }

      h3 {
        font-size: rem(24px);
        font-weight: bold;
        margin-bottom: 0;
      }

      h4 {
        color: #323334;
        font-size: rem(16px);
        font-weight: semibold;
        margin-bottom: rem(2px);
      }

      p {
        font-size: rem(16px);
      }
    }
  }
}

.pl-0 {
  padding-left: 0;
}

.mr-10 {
  margin-right: 10px;
}

input[type='checkbox'].ant-checkbox-input {
  margin: 0;
}

.pl-0 {
  padding-left: 0;
}

.mr-10 {
  margin-right: rem(10px);
}

.rewards-rg-pay-btn {
  font-size: 1rem;
  font-weight: 600;
  border-radius: 10px;
  width: 200px;
  margin-bottom: 10px;
  pointer-events: all !important;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  svg {
    width: 60px;
    height: 26px;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.lose-selection-modal {
  &.ant-modal {
    width: 420px !important;
  }
  .ant-modal-content {
    border-radius: 20px;
  }
  .ant-modal-header {
    border-radius: 20px 20px 0 0;
    padding: 20px 20px 0 20px;
    border-bottom: none;
  }
  .ant-modal-body {
    padding: 6px 20px 24px 20px;
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
    }
  }
  .ant-modal-footer {
    border-radius: 0 0 20px 20px;
    padding: 0 20px 20px 20px;
    border-top: none;
    button.ant-btn-link {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }
    button.ant-btn-link:nth-child(1) {
      color: #72767e;
    }
    button.ant-btn-link:nth-child(2) {
      color: #165f5f;
    }
    .reward-loading-failed-modal .ant-modal-content {
      box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.15);
      border-radius: 20px;

      .anticon-close {
        display: none;
      }
    }
  }
}

.reward-loading-failed-modal {
  &__title {
    display: flex;
    align-items: center;

    strong {
      margin-left: 8px;
    }
  }

  &__cta-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
  }

  button {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #165f5f;
  }
}

.rg-pay-guide-modal {
  .ant-modal-content {
    border-radius: 20px;
  }

  .ant-modal-header {
    border-radius: 20px 20px 0 0;
    padding: 20px;
    border-bottom: none;
  }

  .ant-modal-body {
    padding: 6px 20px 24px 20px;
  }

  .ant-modal-footer {
    border-radius: 0 0 20px 20px;
    padding: 0 20px 20px 20px;
    border-top: none;
  }

  &-title {
    margin: 0;
    font-size: 1.5rem;
    gap: 4px;
    h4 {
      margin: 0;
    }
  }

  &-image-container {
    width: 100%;
    max-width: 100%;
    border-radius: 20px;
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;
    box-shadow: inset 0 4px 0 8px white;
    aspect-ratio: 16 / 9;
  }

  &-content {
    color: #1c1b1f;
    margin: 24px 0;
    font-size: 16px;
    line-height: 24px;
    width: 100%;
  }

  &-button {
    display: block;
    width: 100%;
    border-radius: 0.625rem;
    background-color: #165e5f;
    color: #ffffff;
    border: none;
    &:hover,
    &:focus,
    &:active {
      background-color: #074647;
    }
  }
}

@media (max-width: 768px) {
  .rg-pay-guide-modal {
    &-image-container {
      height: auto;
      padding-top: 56.25%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

@media (min-width: 769px) {
  .rg-pay-guide-modal {
    &-image-container {
      height: 520px;
      padding-top: 0;
      aspect-ratio: auto;
    }
  }
}
