.residents {
  &__content {
    margin-top: 0em !important;
  }
  &__table {
    &-name {
      text-decoration: underline;
      background-color: transparent;
      border: none;

      &:hover {
        cursor: pointer;
        font-weight: 600;
      }
    }
    b {
      padding-left: 8px;
    }
    &-active-resident {
      color: #79e1a2;
    }
    &-inactive-resident {
      color: #e87c65;
    }
  }
}
