// Style for the src/components/checboxmenu/CheckboxMenu.js
.checkbox-menu {
  span {
    text-transform: capitalize;
  }
}
.checkbox-menu-popover {
  &.ant-popover-placement-bottomLeft {
    padding-top: 0px;
    margin-top: 0;
    margin-left: .025em;
    min-width: 120px;

    .ant-checkbox-wrapper,
    .ant-checkbox {
      color: #ffffff;
      margin: rem(2px) 0 !important;
      text-transform: capitalize;
    }
  }

  .ant-popover-content .ant-popover-inner-content {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    background: #1e4c4a;
    min-width: 120px;
    border: 0;
    border-left: 0;
    border-right: 0;
  }

  .ant-checkbox-inner {
    border: 0;
    background: #112d2c;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border: 3px solid #112d2c;
    background: #ffffff;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    opacity: 0 !important;
  }

  .ant-checkbox-checked::after {
    border: 0;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #112d2c;
  }

  .ant-popover-inner {
    box-shadow: none;
    -webkit-box-shadow: none;
  }

  .ant-popover-content .ant-popover-inner-content button.btn-submit {
    margin: rem(10px) 0;
    background: #49d0d4;
    color: #262626;
  }

  .ant-divider-horizontal {
    margin: rem(2px) 0 rem(10px) 0;
    width: 106%;
  }
}