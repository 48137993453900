.business {
  &__content {
    display: flex;
    flex-direction: column;
    padding-top: 1em;

    @media (min-width: $container-lg) {
      flex-direction: row;
    }
  }

  &__img {
    width: auto;
    max-height: 100px;
  }

  &__left {
    min-width: 150px;
    margin-right: 20px;
  }

  &__right {
    min-width: 150px;

    @media (min-width: $container-lg) {
      margin-left: 3em;
    }
  }

  @media (min-width: $container-lg) {
    h3 {
      min-width: 300px;
    }
  }
}
