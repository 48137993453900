.custom_checkbox {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover input ~ .custom_checkbox--check {
    background-color: #f4f4f4;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    margin: 0;

    &:checked ~ .custom_checkbox--check {
      .custom_checkbox--check-display {
        background-color: #48d0d4;
      }
    }
  }

  &--check {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.1rem;
    width: 1.14rem;
    background-color: $white;
    border: 2px solid #878787;
    border-radius: 5px;

    &.disabled {
      border: 2px solid #e6e6e6;

      &:hover {
        cursor: not-allowed;
        background-color: $white;
      }
    }

    &-display {
      position: absolute;
      top: 1.5px;
      left: 1.5px;
      right: 1.5px;
      bottom: 1.5px;
      display: inline-block;
      height: calc(100% - 3px);
      width: calc(100% - 3.2px);
      border-radius: 3px;

      &.disabled {
        &:hover {
          cursor: not-allowed;
          background-color: $white;
        }
      }
    }
  }
}
