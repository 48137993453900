.leads-wrapper {
  padding-top: 2em;
}
.leads {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 100%;

  &__droppablecolumn {
    width: 100%;
    height: 55vh;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    background: map-get($color, 'white');
    border: 1px solid #d5d5d5;
    border-radius: 3px;
    box-shadow: 1px 1px 2px #c2c2c2;
    margin-bottom: 1em;

    &:nth-child(4) {
      margin-bottom: 0;
    }

    @media (min-width: $container-lg) {
      width: 24%;
      margin-bottom: 0;
    }

    &:nth-child(1) {
      .leads__droppablecolumn-title i svg {
        color: #79e1a2;
      }
    }

    &:nth-child(2) {
      .leads__droppablecolumn-title i svg {
        color: #ffd278;
      }
    }

    &:nth-child(3) {
      .leads__droppablecolumn-title i svg {
        color: $theme-primary;
      }
    }

    &:nth-child(4) {
      .leads__droppablecolumn-title i svg {
        color: #c2c2c2;
      }

      .leads__draggablecard {
        background: #dcdcdc;
      }
    }

    &-title {
      border-bottom: 1px solid #d5d5d5;
      box-shadow: 0.5px 0.5px 2px #c2c2c2;
      background: #f5f7fa;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      position: sticky;
      width: 100%;
      height: 35px;
      padding: 0 0 0 0.4em;
      z-index: 2;
      overflow: hidden;

      h4 {
        font-weight: bold;
        line-height: 12px;
      }

      &-icons {
        height: 50px;
        width: 50px;
        position: absolute;
        right: 10px;
        bottom: -10px;
      }
    }

    &-see-more-leads {
      margin: 0.7em 0.4em 1.1em 0;
      height: 10%;
      background: rgb(242, 242, 242);
      width: 98.5%;
      min-height: 20px;
      text-align: left;
      &:hover {
        border: 2px solid #d5d5d5;
      }
      span {
        font-size: 14px;
        color: map-get($color, 'light-text');
        text-decoration: underline;
      }
    }

    .ant-btn {
      &:hover {
        span {
          color: map-get($color, 'dark-text') !important;
        }
      }
    }

    &-unlock-prospect {
      margin: 1em;
      a {
        color: $antd-button-primary;
        &:hover {
          color: $default-focus-bg;
        }
      }
    }

    &-draggablecards {
      border: 3px solid transparent;
      border-radius: 7px;
      height: 100%;
      overflow-y: scroll;
      position: relative;

      &-droppableoverlay {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        background: rgba(220, 220, 220, 0.9);
        z-index: 2;
        position: absolute;
        cursor: grab !important;

        .inner-content {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          border: dashed 3px #999999;
          border-radius: 20px;
          height: 80%;
          width: 80%;

          .action-name {
            font-weight: bold;
            font-size: 16px;
            color: #999999;
          }
        }
      }
    }
  }

  &__draggablecard {
    padding: 0.4em;
    margin: 0.7em 0.4em 0.7em 0;
    border: 1px solid #d5d5d5;
    box-shadow: 0px 1px 3px #c2c2c2;
    border-radius: 3px;
    background: #f5f7fa;
    width: 98.5%;
    min-height: 20px;

    &:hover {
      .applied_and_lease_signed {
        .un_applied,
        .un_signed_lease {
          opacity: 1;

          &:hover {
            font-weight: bold;
          }
        }
      }
    }

    &:first-child {
      margin: 0.7em 0.4em 0.7em 0;
    }

    &:last-child {
      margin: 0.7em 0.4em 0.7em 0;
    }

    &-row {
      display: flex;
      justify-content: space-between;

      .header-left {
        display: flex;
        flex-direction: row;
        justify-content: center;

        &__avatar {
        }

        &__namedate {
          margin-left: 1em;

          &-name {
            font-weight: bold;
            color: #333333;
          }

          &-date {
            display: flex;
            flex-direction: column;
            color: #808080;
            font-size: 10px;
          }
        }
      }

      .header-right {
        font-size: 10px;
      }

      .conversations {
        display: flex;
        flex-direction: column;
        width: 55%;
        margin-top: 0.5em;
        font-size: 10px;

        &__label {
          color: #666666;
        }

        &__info {
          display: flex;
          flex-direction: column;

          &-resident {
            color: #808080;
            display: block;
          }
        }

        @media (max-width: $container-lg) {
          width: 70%;
        }
      }

      .applied_and_lease_signed {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 45%;
        font-size: 10px;
        margin-top: 0.5em;
        align-self: flex-end;
        color: #1f1f1f;

        @media (max-width: $container-lg) {
          width: 30%;
        }

        .un_applied,
        .un_signed_lease {
          padding: 3px 5px;
          border-radius: 5px;
          cursor: pointer;
          opacity: 0;
        }

        .applied,
        .signed_lease {
          padding: 3px 10px;
          border-radius: 5px;
        }

        .applied {
          background-color: #fdd17e;
          height: 23px;
        }

        .signed_lease {
          background-color: #56ced3;
        }
      }
    }
  }

  &__draggablecardyellow {
    background: linear-gradient(-45deg, #f5f7fa, #fff8ce);
    background-size: 400% 400%;
    -webkit-animation: Gradient 10s ease infinite;
    -moz-animation: Gradient 10s ease infinite;
    animation: Gradient 10s ease infinite;
  }

  &__draggablecardred {
    background-image: linear-gradient(240deg, #f5f7fa -26.48%, #fad3c7 73.52%);
    background-size: 400% 400%;
    -webkit-animation: Gradient 10s ease infinite;
    -moz-animation: Gradient 10s ease infinite;
    animation: Gradient 10s ease infinite;
  }
}

.actionwindow {
  &__header,
  &__childrenwrapper {
    padding: 15px;
    background: $white;
    box-shadow: 2px 2px 2px rgba(0, 0, 1, 0.1);

    @media (max-width: $container-sm) {
      width: calc(100% - 70px);

      .ant-steps {
        overflow-y: auto;
      }

      .ant-form-item {
        margin-bottom: 10px;
      }

      #moved-in-form_unit_number,
      #moved-in-form_lease_term {
        width: 30%;
      }
    }
  }

  &__header {
    margin-bottom: 20px;

    &-nameimage {
      display: flex;
      align-items: center;
      font-weight: bold;
      color: $black;

      .ant-avatar-circle {
        margin: 0 20px 0 0;
      }
    }

    &-instruction {
      margin-top: 15px;
    }

    @media (max-width: $container-sm) {
      width: calc(100% - 60px);
    }
  }

  &__childrenwrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-children {
      width: 60%;

      @media (max-width: $container-sm) {
        width: 80%;
      }

      .steppers {
        margin: 0 0 25px 0;
      }

      .form-button-items .ant-form-item-children {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-top: 25px;
      }
    }
  }
}

.status-form-wrapper {
  &__header {
    font-weight: bold;
    margin: 0 0 15px 0;
  }

  &__text {
    padding-bottom: 40px;
    font-size: 12px;
  }

  &__body {
    width: 100%;
    margin-left: 1% !important;
    label {
      padding-right: 10px;
    }

    &-lease-term {
      .ant-input {
        width: 9%;
        margin-right: 10px;
      }
    }

    &-unit-number {
      .ant-input {
        width: 12%;
      }
    }

    &-button-row {
      margin-top: 25px;
      .ant-form-item-children {
        display: flex;
        justify-content: flex-end;
      }
      button {
        &:first-child {
          margin-right: 10px;
        }
      }

      .ant-btn-primary {
        font-size: 13px !important;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 10px;
        padding-left: 10px;
        text-align: center;
      }

      &-deactivate-reward {
        border: none;
        box-shadow: none;
        color: red;
        &:hover {
          color: inherit;
          span {
            text-decoration: underline;
          }
        }
      }

      &-activate-reward {
        border: none;
        box-shadow: none;
        color: $theme-primary;
        &:hover {
          color: inherit;
          span {
            text-decoration: underline;
          }
        }
      }

      &-cancel {
        border: none;
        box-shadow: none;

        &:hover {
          color: inherit;
          span {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
