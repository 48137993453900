.avatar-crop-modal {
  text-align: center;
  white-space: nowrap;
}

.avatar-crop-modal:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 0;
  background: green;
}

.avatar-crop-modal .ant-modal {
  display: inline-block;
  vertical-align: middle;
  text-align: left;

  .ant-modal-body {
    display: flex;
    width: 100%;
    justify-content: center;
    background: $black;
  }

  .ant-modal-title {
    font-size: 16px;
    font-weight: bold;
  }
}
