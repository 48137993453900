.clipboard {
  width: 100%;
  min-width: 325px;
  height: 30px;
  background: white;
  border-radius: 5px;
  border: 2px solid #d5d5d5;
  line-height: 25px;
  padding-left: 15px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;

  i {
    border-left: 2px solid #d5d5d5;
    cursor: pointer;
    display: block;
    line-height: 30px;
    text-align: center;
    width: 30px;
  }

  input {
    height: auto;
    margin-bottom: 0;
    border-radius: 0;
    border: 0;
    padding: 0;
  }

  @media (max-width: $container-md) {
    width: 100%;
  }
}

.clipboard-button {
  margin-left: 20px;
  border-radius: 5px;
  border: 2px solid #d5d5d5;

  &:hover, &:focus {
    color: #49d0d4;
    background-color: #fff;
    border-color: #49d0d4;
  }
}

.clipboard-container {
  display: inline-flex;
}
