.panel {
  background: rgb(255, 255, 255);
  border-radius: 0px;
  margin-top: 24px;

  &__header {
    position: relative;
    padding: 12px 16px;
    padding-left: 40px;
    line-height: 22px;
    cursor: pointer;
    transition: all 0.3s;
    height: 4.85714rem;
    display: flex;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;

    @media (max-width: $container-lg) {
      flex-direction: column;
      height: auto;
      padding: 1.2rem 2.5rem;
  
      h3 {
        width: 100%;
        margin-bottom: 1rem;
      }
    }
  
    &__extra {
      @media (max-width: $container-lg) {
        width: 100%;
      }
    }

    i {
      color: inherit;
      font-style: normal;
      line-height: 0;
      text-align: center;
      text-transform: none;
      vertical-align: -0.125em;
      position: absolute;
      top: 50%;
      left: 16px;
      display: inline-block;
      margin-top: 2px;
      font-size: 12px;
      line-height: 46px;
      transform: translateY(-50%);
    }

    &--disabled {
      cursor: not-allowed;
      opacity: 0.7;
    }
  }

  &__content {
    width: 100%;
    height: 0;
    overflow: hidden;
    transition: opacity 0.3s ease-in-out 0s, height 5s ease-in-out 0s;

    &--open {
      overflow: hidden;
      height: auto;
      padding: 4px 16px 36px 16px;
    }
  }
}
