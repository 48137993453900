.topic-guide {
  padding: 15px;
  background: $white;
  min-height: 100vh;
  min-width: 100%;
  margin-left: -27px;
  margin-top: -20px;
  margin-right: -18px;
  padding-left: 30px;

  &__header {
    display: flex;
    justify-content: space-between;

    &--text {
      h1{
        font-size: 50px;
        font-weight: bolder;
        display: inline-block;
        margin-top: 10px;

        @media (max-width: 425px) {
          margin-top: 0px;
          display: block;
        }
      }
      
    }

    img {
      width: 80px;
      height: 80px;
      margin-right: 10px;
      margin-top: -15px;

      @media (max-width: 425px) {
        margin-top: 0px;
        margin-right: 0px;
      }
    }

    @media (max-width: 425px) {
      display: flex;
      flex-direction: row;
    }
  }

  &__topic-search {
    width: 20%;

    button {
      background: rgb(60,60,60);
      border-color: rgb(60,60,60);
    }
    button:hover {
      background: rgb(60,60,60);
    }

    input {
      box-shadow: none;
    }

    input:focus, input:hover {
      border-color: rgb(60,60,60);
    }
    @media (max-width: 425px) {
      width: 100%;
    }
  }

  &__topics {
    width: 40%;

    @media (max-width: 425px) {
      width: 100%
    }
  }

  &--topic-header {
    padding-left: 50px;
    font-size: 40px;
    font-weight: bold;

    @media (max-width: 425px) {
      font-size: 35px;
    }
  }

  &--topic-detail {
    padding-left: 50px;
  }

  .anticon.anticon-caret-right.ant-collapse-arrow {
    left: 0px;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 12px 0px;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
  }

  .ant-collapse-content {
    padding: 0px;
  }

  .top-words {
    display: grid;
    align-content: space-evenly;
    grid-template-columns: auto auto auto auto auto;
    gap: 5px;
    font-size: 15px;
  }

  .top-words-heading {
    margin-bottom: 0px;
    font-size: 20px;
    font-weight: bold;
  }

  .related-sentence {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 15px;
  }

  .related-topics {
    display: grid;
    align-content: space-evenly;
    grid-template-columns: auto auto auto;
    gap: 5px;
    font-size: 15px;

    a {
      text-decoration: underline;
      
    }
    a:hover {
      color: #4d4d4d;
    }
  }
  
  &--search-button {
    color: rgb(60,60,60);
  }

  &__no-results {
    margin-top: 140px;
  }

}
