$navigation-height: 50px;
$minimal-footer-height: 30px;
$content-viewport-height: calc(100vh - (#{$navigation-height} + #{$minimal-footer-height}));
$container-xsm: 320px;
$container-sm: 420px;
$container-md: 720px;
$container-lg: 960px;
$container-xlg: 1024px;
$container-xxlg: 1290px;
$container-xxxlg: 1441px;
$container-hd: 1920px;

$position-right-sm: calc((100vw - (#{$container-sm}))/2);
$position-right-md: calc((100vw - (#{$container-md}))/2);
$position-right-lg: calc((100vw - (#{$container-lg}))/2);

.main-content {
  margin-top: $navigation-height;
  min-height: $content-viewport-height;
}
