.breadcrumbs-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-left: 0;
  list-style-type: none;

  @media (max-width: $container-sm) {
    flex-direction: column;
  }

  li {
    margin: 0 0 0 2px;
    text-transform: capitalize;
    &:hover {
      color: $theme-primary;
    }

    a {
      color: $light-text;
    }

    &::after {
      content: " / "
    }

    &:last-child {
      &::after {
        content: ""
      }
      font-weight: 600;
    }
  }

  li:nth-of-type(1) {
    margin-left: 0;
  }
}
