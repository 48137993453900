.properties-select {
  height: 31px;
  border-radius: 5px;
  z-index: 10;

  &__button {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    text-align: left;
    padding-left: 12px;
    cursor: pointer;
  }

  &__children {
    height: 100%;
    background: transparent;
    border: none;
    text-align: left;
    cursor: pointer;
    font-weight: 600;
    font-size: 15px;

    svg {
      margin-left: 8px;
    }
  }
}

.properties-dropdown {
  width: 100%;
  max-height: 444px;
  background-color: #FFFFFF;
  border: 1px solid #707070;
  border-radius: 5px;
  overflow-y: auto;
  display: none;
  flex-direction: column;
  transition: top 0.5s ease-in;
  position: absolute;
  z-index: 11;

  &.visible {
    display: flex;
  }

  &:hover {
    display: flex;
  }

  ul {
    margin: 0;
    padding-inline-start: 0px;
    padding: 18px 5px 0 10px;
    list-style-type: none;
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1;
  }

  li {
    margin-bottom: 13px;
    display: flex;
    padding-left: 32px;
    cursor: pointer;
    box-sizing: border-box;
  }

  .custom_checkbox--check {
    border: 1px solid #1E4C4A;
  }

  .custom_checkbox--check-display {
    top: 1.5px;
    left: 1.5px;
    right: 1px;
    bottom: 1px;
  }

  .custom_checkbox input:checked ~ .custom_checkbox--check .custom_checkbox--check-display {
    background-color: #1E4C4A;
  }

  li.all {
    padding-left: 0px;
  }

  &__text {
    margin-left: 33px;
    font: normal normal normal 18px/23px Source Sans Pro;
    letter-spacing: 0px;
    color: #242424;
    margin-top: -2px;
  }

  &__ctas {
    box-shadow: 0px -1px 3px #00000029;
    display: flex;
    justify-content: space-between;
    padding: 10px 11px;
    transition: all 0.5s ease-in;
  }
}

#rewards-page-list-box-select-drop-down {
  width: 235px;
}
