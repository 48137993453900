.newusermodal {

  &__permission {
    color: $theme-primary;

    &:hover {
      cursor: pointer;
    }
  }

  &__new-user {
    padding: 24;
    background: #fff;
    min-height: 360;
  }

  &__choose-properties-text {
    float: right;
    font-size: 12px;
    padding-top: 3px;

  }
  
  &__invalid-email-message {
    color: red;
  }
  
  &__resend-invite-pointer {
    cursor: pointer;
    text-decoration: underline;
    color: blue;
    background-color: transparent;
    border: none;
  }
  
  &__cancel-invite {
    cursor: pointer;
    margin-left: 2%;
    text-decoration: underline;
    color: map-get($color, "red");
    background-color: transparent;
    border: none;
  }
  
  &__invite-cancel-buttons {
    justify-content: space-between;
    display: flex;
  }
}
