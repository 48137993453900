.resident {

  &__bio {
    display: flex;
    margin-bottom: 1em;

    h3 {
      margin-left: 0.5em;
      margin-top: 0.1em;
    }
  }

  &__top {
    background: $white;
    padding: 1em;
    margin: -23px -28px 0 -28px;
  }

  &__comment {
    display: flex;
    justify-content: space-between;

    p {
      font-size: 1em;
    }
  }

  &__cta {
    background: $red;
    border-color: $red;

    &:hover {
      background: $red;
      border-color: $red;
      color: $white;
      opacity: 0.7;
    }

    &:focus {
      background: $red;
      border-color: $red;
    }
  }


  &__header {
    display: flex;
    flex-direction: column;
    padding: 10px 20px;

    &-top {
      display: flex;
      justify-content: space-between;
    }

    &-bio {
      display: flex;
    }

    &-name {
      padding: 5px 10px;
    }

    &-content {
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      @media (min-width: $container-md) {
        flex-direction: row;
        width: 100%;
      }

      @media (min-width: $container-lg) {
        width: 90%;
      }
    }

    &-copy {
      width: 400px;
    }

    &-image {
      width: 100px;
      height: 100px;
      border-radius: 50px;
    }

    &-details {
      padding: 0px 10px;
      @media (min-width: $container-md) {
        width: 50%;
      }

      &-pending{
        color: $button-danger;
      }
    }
  }

  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #FFFFFF !important;
  }
  
  &__rewards {
    margin-top: 2em;
    padding: 2em;
    background: white;
    
    &-toggle {
      float: right;
      display: block;
      margin-top: -30px;
    }

    .ant-table-wrapper {
      margin-top: 20px;
    }

  }

  &__bottom {
    margin-top: 2em;
    padding: 2em;
    background: white;
  }

  &__options {
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;

   .ant-radio-inner {
      border-radius: 0;
   }

    .ant-radio-input:focus + .ant-radio-inner {
      border-color: $theme-default-background;
      background: $dark-gray;
    }
  }
}
