.bulk-updates {
  padding: 42px 42px 42px 26px;
  background: $white;
  min-height: calc(100vh - 158px);
  min-width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: $container-lg) {
    padding: 26px;
  }

  &__content {
    flex: 1;
    display: flex;
    margin-top: 15px;
    flex-direction: column;

    &__section {
      margin-bottom: 74px;
    }

    &__section label {
      margin-bottom: 24px;
      display: block;
      z-index: 1;
    }

    &__section input.ant-select-search__field {
      padding-left: 0;
    }

    &__section img {
      margin-right: 14px;
    }

    &__section button.ant-btn {
      margin-left: 24px;
    }

    &__section div.csv-drop {
      text-align: center;
      background: #efefef;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 140px;
      border-radius: 12px;
      border-width: 1px;
      border-color: #232323;
      border-style: dotted;
    }

    &__section button.csv-drop-choose-file {
      color: #49d0d4;
      cursor: pointer;
      border: none;
      background-color: transparent;
      padding: 0;
    }
  }
}

.bulk-updates-preview {
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06);

  &__header {
    display: flex;
    background-color: #FAFAF9;

    .ant-typography {
      font-size: 12px;
      flex: 1;
      padding: 12px 24px;
    }
  }

  &__collapse {
    border-radius: 0;
    border-left: none;
    border-right: none;
  
    .ant-collapse-item {
      background-color: #fff;
  
      &.ant-collapse-item-active {
        background-color: #F5F5F4;
      }

      .ant-collapse-header .ant-typography {
        color: inherit;
      }
    }

    .ant-collapse-content-box {
      padding: 0;
    }
  
    .ant-collapse-content.ant-collapse-content-active {
      background-color: #F5F5F4;
      padding: 0;
      border: none;
    }
  }

  &__item {
    display: flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    height: 72px;
    border-bottom: 1px solid #E9E7E4;
    align-items: center;

    & > span.ant-typography {
      width: calc(100% / 3);
      padding: 0px 24px;

      &:nth-of-type(1) {
        padding-left: 40px;
      }
    }

    &.cta {
      height: 50px;
      justify-content: flex-end;
      background-color: #fff;
      padding-right: 24px;
    }
  }

  &__item-error {
    display: flex;
    flex-direction: column;

    .ant-typography.error {
      color: #A62017;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      display: flex;
      align-items: center;

      .anticon {
        margin-right: 6px;
      }
    }
  }
}
