.payment-method {
  display: flex;
  padding: 0.8rem 1.5rem;
  border-bottom: 2px solid #bbbbbb;
  line-height: 16px;

  @media (max-width: $container-sm) {
    flex-wrap: wrap;
  }

  &:last-child {
    border-bottom: none;
  }

  &.remove-account {
    padding: 1.5rem 0;
    border-bottom: 2px solid #bbbbbb;
    position: relative;
  }

  &__details {
    font-size: rem(14);
    color: $black;
    margin-left: 2rem;
    margin-top: 0.1rem;

    &.remove-account {
      margin-left: 1rem;
      width: 200px;

      @media (max-width: $container-lg) {
        width: 40%;
      }
    }
  }

  &__verify {
    display: inline-flex;

    @media (max-width: $container-sm) {
      width: 100%;
      text-align: left;
      padding-left: 32px;
      padding-top: 10px;

      &.remove-account {
        padding-left: 16px;
      }
    }

    &-text {
      color: #909090;
    }

    &-cta {
      margin-left: 1.5rem;
      border: none;
      color: #0071bc;
      text-decoration: underline;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__type {
    font-size: rem(12);
    font-weight: $font-weight-medium;
    width: 150px;
    text-align: center;

    @media (max-width: $container-sm) {
      display: block;
      width: 100%;
      text-align: left;
      padding-left: 32px;
      padding-top: 10px;

      &.remove-account {
        padding-left: 16px;
      }
    }

    &.remove-account {
      margin-left: 0;
    }
  }

  &__ellipses {
    display: flex;
    width: 30px;
    justify-content: space-evenly;
    align-items: center;
    right: 2rem;
    top: 2rem;
    position: absolute;
    height: 10px;

    @media (max-width: $container-lg) {
      margin-left: 60px;
    }

    &:hover {
      cursor: pointer;
      background-color: #e6e6e6;
    }

    span {
      width: 3px;
      height: 3px;
      border-radius: 60%;
      background-color: #666666;
      display: inline-block;
    }
  }
}

.ant-popover-content {
  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner-content {
    padding: 3px 16px;
    border: 1px solid #bbbbbb;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.1);

    &:hover {
      cursor: pointer;
    }

    button {
      color: #a95843;
      font-weight: $font-weight-medium;
      border: none;
      padding: 0;
    }
  }
}
