.neighborNurtureV1 {
  &.neighborNurture, .neighborNurture {
    &__wrapper {
      color: #262626;
    }

    &__header {
      justify-content: space-between;
      padding: 1em;

      h3 {
        font-size: 26px;
        position: relative;
      }

      svg {
        vertical-align: sub;
        margin-right: 10px;
      }
    }

    &__lead-sources-header {
      max-width: 920px;
    }

    &__subheader {
      font-size: 20px;
    }

    &__description {
      font-size: 15px;
      color: #262626;
    }

    &__box {
      strong {
        display: block;
      }
    }

    &__container {
      padding: 24px;
      background: $white;
      background: white;
      margin: 2em 0;

      &:first-of-type {
        margin-top: 0;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &__table-wrapper {
      justify-content: space-between;
      padding: 1em;

      h4 {
        margin: 0;
        font-size: 22px;
        position: relative;
      }

      &_row {
        color: #6A6A6A;
      }

      &_column {
        &:first-of-type {
          padding-left: 2em!important;
        }

        span {
          color: #6A6A6A;
        }
      }

      .ant-table {
        margin-top: 1em;

        .ant-checkbox-inner {
          background-color: #fff;
          border-radius: 4px;
          border-color: #707070!important;
          width: 20px;
          height: 20px;

          &::before {
            background-color: #fff;
            width: 14px;
            height: 14px;
            content: ' ';
            position: absolute;
            left: 2px;
            top: 2px;
          }
        }

        .ant-checkbox-checked .ant-checkbox-inner {
          &::before {
            background-color: #49D0D4;
            border-radius: 2px;
            width: 14px;
            height: 14px;
            content: ' ';
            position: absolute;
            left: 2px;
            top: 2px;
          }

          &::after {
            border-color: #fff;
          }
        }
      }
    }

    &__search-wrapper {
      padding: 0.5em 0;
      max-width: 200px;
    }

    &__filter-wrapper {
      padding: 0.5em 0;
      max-width: 100px;
      margin-right: 10px;
    }
  }

  .ant-input-search, .ant-select {
    padding-top: 0;

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #575757;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #575757;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
      color: #575757;
    }

    input.ant-input, .ant-select-selection {
      border-radius: 10px;
      border-color: #A3A3A3;
    }

    i.ant-input-search-icon, i.ant-select-arrow-icon {
      color: #575757;
    }
  }

  .btn, .swt {
    &-rg {
      background-color: #1E4C4A;
      border: 1px solid #575757;
    }
  }

  .btn {
    &-rg {
      margin-top: 0.5em;
      min-width: 100px;
    }
  }

  .swt {
    &-rg {
      min-width: 35px;
      margin: 0 10px;
    }
  }
}

@media (max-width: 960px) {
  .neighborNurtureV1 {
    padding: 0;

    &.neighborNurture, .neighborNurture {

      &__wrapper {
        .neighborNurture__table-wrapper {
          padding: 0.5em;

          .ant-table-wrapper {
            overflow: visible;
          }
        }

        .ant-table-tbody td:nth-child(even), .ant-table-thead th:nth-child(even) {
          min-width: auto;
        }
      }

      &__container {
        padding: 1em;

        .ant-spin-container ul {
          position: relative;
          margin-right: 0!important;
        }

        .ant-pagination {
          &.mini .ant-pagination-item {
            margin-right: 4px;
            border: 1px solid #d9d9d9;
            background-color: #fff;
          }
        }
      }

      &__table-wrapper {
        &_column {
          &:last-of-type {
            padding-right: 1em!important;
          }
        }
      }

      &__table_column {
        max-width: calc(100vw - 182px);
      }
    }
  }
}