.no-data-source {
  background-color: #fefae7;
  padding: 2rem 4rem 4rem 2rem;
  margin-bottom: rem(24);
  font-size: rem($h4-font-size);
  position: relative;

  button {
    padding: 0 2rem;
    position: absolute;
    right: 1rem;
  }
}

.panel_number {
  height: 40px;
  line-height: 24px;
  min-width: 24px;
  width: 30px;
  padding: 2px 9.2px;
  font-size: rem($h4-font-size);
  font-weight: bold;
  border-radius: 50%;
  margin: 0 10px 0 5px;
  vertical-align: middle;
  color: white;
  background: #d5d5d5;

  @media (max-width: $container-lg) {
    margin: 0 10px 0 0;
  }

  &.property-section {
    padding: 6px 10px;
  }

  &.updated {
    background: $progress-color;
  }
}

.panel__header-title {
  font-weight: 600;
  font-size: rem($h4-font-size);
  vertical-align: middle;

  &-extra_label {
    vertical-align: middle;
    font-size: rem($h4-font-size);
  }

  &-extra_text {
    color: $indian-red;
    font-size: rem($h4-font-size);
    font-weight: bold;
    padding: 0 rem(20);
    vertical-align: middle;

    &.updated {
      color: $progress-color;
    }
  }

  &-messenger-extra {
    display: flex;

    &-label {
      display: flex;
      flex-direction: column;
      text-align: right;
      margin-right: rem(10);
      justify-content: space-evenly;

      &-cta {
        text-decoration: underline;
        font-size: rem(12px);
        color: $dark-gray;
        cursor: pointer;

        i {
          margin-right: rem(5px);
        }
      }
    }

    &-status {
      padding: rem(8px) rem(30px);
      color: $white;
      background: $indian-red;
      border-radius: rem(3px);

      &.installed {
        background: $progress-color;
      }
    }
  }
}

.panel-description {
  margin-left: 30px;
  margin-bottom: 30px;
  padding: 0px 15px;
  border-left-style: solid;
  border-left-width: 2px;
  border-left-color: $dim-gray;
  font-size: 16px;

  &--more-margin {
    margin-left: 90px;

    @media (max-width: $container-sm) {
      margin-left: 30px;
    }
  }
}

.panel-input-help {
  color: $light-text;
  line-height: normal;
  margin-top: 2px;
  padding: 0px 3px;
}

.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: rem(24px);
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.ant-collapse-content {
  width: 100%;
  padding: 20px 0;
}

.config-messenger,
.more__team-option,
.more-property-options {
  text-align: right;
  margin-top: 10px;

  &--margin-bottom {
    margin-bottom: 30px;
  }

  &.in-form {
    text-align: left;
    margin-bottom: 10px;
  }

  span {
    display: inline;
  }

  .link {
    padding: 0 20px 0 0;
    font-size: 15px;
    text-decoration: underline;
    color: map-get($color, "DeepSkyBlue");

    &:hover {
      cursor: pointer;
      color: map-get($color, "DeepSkyBlueHov");
    }
  }
}

.sent-to-wrapper {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  margin-top: rem(15);

  @media (max-width: $container-lg) {
    flex-direction: column;
    width: 440px;
    margin: auto;
    padding: 0 0.5rem;
  }

  &__emails {
    width: 20.42857rem;
    margin-left: rem(15);

    @media (max-width: $container-lg) {
      margin-left: 0;
    }

    &-wrapper {
      height: rem(53.7031);

      &.display-more {
        height: rem(74.7031);
        overflow: auto;
        background-color: map-get($color, "GhostWhite");
        border-radius: 3px;
        padding: 0 rem(10) rem(10) rem(10);
        margin-bottom: rem(5);
      }
    }

    p {
      margin-bottom: rem(5);

      span {
        font-weight: $font-weight-medium;
        margin-left: rem(10);

        &:hover {
          cursor: pointer;
        }
      }
    }

    &-view-more {
      text-decoration: underline;
      font-size: rem(12);
      color: $dark-gray;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.installation__instructions {
  background-color: map-get($color, "GhostWhite");
  margin: 10px 70px;
  padding: 20px;
  position: relative;

  @media (max-width: $container-lg) {
    margin: 0px 5px;
  }

  @media (max-width: $container-md) {
    margin: 0px 5px;
  }

  @media (max-width: $container-sm) {
    margin: 0px 10px;
  }

  .installation {
    display: flex;
    margin-left: 10%;

    &-tag {
      padding-top: 20px;
      width: 16.7%;
    }

    &-code {
      width: 58.3%;
      margin: 0px 5px;
      border-radius: 5px;
    }

    &-copy {
      width: 10%;
      border-radius: 5px;

      &:hover {
        cursor: pointer;
      }
    }

    @media (max-width: $container-lg) {
      flex-direction: column;
      margin-left: 0;

      &-tag {
        padding-top: 18px;
        width: 80%;
      }

      &-code {
        width: 98%;
        margin: 0px;
      }

      &-copy {
        width: 25%;
      }
    }

    @media (max-width: $container-md) {
      flex-direction: column;
      margin-left: 0;

      &-tag {
        padding-top: 20px;
        width: 80%;
      }

      &-code {
        width: 98%;
        margin: 0px;
      }

      &-copy {
        width: 25%;
      }
    }

    @media (max-width: $container-sm) {
      flex-direction: column;
      margin-left: 2%;

      &-tag {
        padding-top: 20px;
        width: 80%;
      }

      &-code {
        width: 98%;
        margin: 0px;
      }

      &-copy {
        width: 25%;
      }
    }
  }

  &-copy-feedback {
    position: absolute;
    top: 33%;
    right: 5%;

    @media (max-width: $container-lg) {
      top: 43%;
      left: 35%;
    }

    &.body {
      top: 53%;

      @media (max-width: $container-lg) {
        top: 90%;
      }
    }

    i {
      margin-right: rem(5px);
    }
  }
}

.invite-team-content {
  display: flex;
  justify-content: center;

  &__label {
    width: 28%;
    text-align: right;
    margin-right: 20px;

    &__text {
      margin-bottom: 6px;
    }

    span {
      display: block;
      font-size: 0.875rem;
      width: 70%;
      float: right;
      color: #959595;

      @media (max-width: $container-lg) {
        width: 100%;
        margin-bottom: 6px;
      }
    }
  }

  &__fields {
    width: calc(72% - 20px);

    @media (max-width: $container-lg) {
      .ant-row.ant-form-item {
        width: 100%;
      }

      div[class^="ant-col"] {
        width: 100%;
      }

      .ant-select {
        width: 100% !important;
      }
    }
  }

  &__fields-cta {
    margin-left: rem(15px);
    font-weight: $font-weight-medium;

    &:hover {
      cursor: pointer;
    }

    &.remove {
      color: $indian-red;
    }
  }
  &__fields-span {
    text-transform: capitalize;
  }
  .ant-form-inline .ant-form-item {
    margin-right: 0;
  }

  p {
    font-size: medium;
  }

  @media (max-width: $container-lg) {
    flex-direction: column;
    margin-left: 0;

    &__label {
      width: 100%;
      text-align: left;
      margin-right: 0;
    }

    &__fields {
      width: 98%;
    }
  }

  @media (max-width: $container-md) {
    flex-direction: column;
    margin-left: 0;

    &__label {
      width: 100%;
      text-align: left;
      margin-right: 0;
    }

    &__fields {
      width: 98%;
    }
  }

  @media (max-width: $container-sm) {
    flex-direction: column;
    margin-left: 2%;

    &__label {
      width: 100%;
      text-align: left;
      margin-right: 0;
    }

    &__fields {
      width: 100%;
      position: relative;
    }
  }

  &__errors {
    color: $antd-red;
    margin-bottom: 10px;
  }

  &__invite-button {
    color: #49d0d4;
    font-weight: $font-weight-medium;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.add-members {
  position: relative;
  width: 450px;

  @media (max-width: $container-lg) {
    width: 100%;
  }

  .anticon-search {
    position: absolute;
    z-index: 1;
    top: 10px;
    left: 10px;
  }

  .ant-input {
    padding-left: 32px;
  }

  .ant-spin {
    position: absolute;
    z-index: 1;
    top: 9px;
    right: 10px;
  }

  &__drop-down {
    display: flex;
    float: left;
    width: 100%;
    flex-direction: column;
    list-style-type: none;
    z-index: 3;
    margin-top: -10px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
    border-left: 1px solid #d9d7da;
    border-right: 1px solid #d9d7da;
    border-bottom: 1px solid #d9d7da;
    padding-top: 5px;
    transition: opacity 0.2s linear;

    &__item {
      padding: 10px;
      cursor: pointer;

      &.error-message {
        color: map-get($color, "indian-red");
        cursor: default;
      }

      &.invite-user-cta {
        color: #49d0d4;
        font-weight: $font-weight-medium;
        border-top: 1px solid #d9d7da;
      }
    }
  }

  &__user-list {
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
    list-style-type: none;
    transition: opacity 0.2s linear;

    &__wrapper {
      float: right;
      background-color: #e6e6e6;
      width: 100%;
      padding: 25px 16px 16px 16px;
      border-radius: 0 0 5px 5px;
      margin-top: -10px;

      button {
        float: right;
        margin-top: 20px;
      }
    }

    &__item {
      margin-bottom: 10px;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;

      &__cta {
        color: $indian-red;
        cursor: pointer;
        margin-left: 10px;
        display: inline-flex;
        align-items: center;
        font-weight: $font-weight-medium;

        .anticon-close {
          margin-right: 6px;
        }
      }
    }
  }

  &__errors {
    margin-top: 16px;
    color: $indian-red;

    p {
      margin-bottom: 4px;
    }
  }
}

.set-reward-options {
  margin-left: 5%;
  display: flex;

  @media (max-width: $container-lg) {
    padding: 0 5%;
    margin-left: 0;
    flex-direction: column;

    div[class^="ant-col"] {
      width: 100%;
      margin-left: 0;
    }
  }

  &__left {
    .ant-select-selection {
      &--single {
        height: rem(35px) !important;
        padding: rem(4px) 0px;
      }
      &__placeholder {
        line-height: 10px;
      }
    }

    .ant-form-item {
      margin-top: 15px;

      label {
        float: right;

        @media (max-width: $container-lg) {
          float: left;
        }
      }
    }

    .ant-form-item-label {
      text-decoration: right;
      padding-right: 2em;

      label {
        float: right;

        @media (max-width: $container-lg) {
          float: left;
        }
      }
    }

    .ant-select-selection {
      &__rendered {
        height: rem(35px) !important;
      }
    }
  }

  &__right {
    &-text {
      background-color: #e7e7e7;
      padding: 0px 10px 6px;
      margin-bottom: 20px;
      font-size: 12px;
      width: 100%;
      border-radius: rem(3px);

      &-title {
        padding: 4px 0px;
        font-size: 16px;
        font-weight: 600;
      }

      @media (max-width: $container-xlg) {
        margin-top: 20px;
      }

      @media (max-width: $container-lg) {
        margin-top: 20px;
      }

      @media (max-width: $container-md) {
        margin-top: 20px;
      }
    }

    &-settings-cta {
      text-decoration: underline;
      font-size: rem(12);
      width: fit-content;
      border: none;
      background-color: transparent;

      &:hover {
        cursor: pointer;
        color: #505050;
      }
    }

    &-turn-off-reward {
      font-size: rem(12);
      margin-top: 0.8rem;

      .cancel {
        color: map-get($color, "indian-red");
        font-weight: $font-weight-medium;
      }
    }
  }
}

.install-messenger-form__wrapper {
  width: 100%;
  box-sizing: border-box;

  @media (max-width: $container-lg) {
    padding: 0 0.5rem;
    margin-bottom: 1rem;
  }

  .ant-form-inline {
    width: 100%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;

    .ant-row.ant-form-item {
      @media (max-width: $container-lg) {
        margin-right: 0;

        &:last-child {
          .ant-col.ant-form-item-control-wrapper,
          .ant-form-item-control,
          .ant-form-item-children {
            display: flex;
            align-items: flex-end;
            height: 100%;
            position: relative;
          }
        }
      }
    }
  }

  @media (max-width: $container-lg) {
    .ant-form-item-control-wrapper {
      width: calc(100% - 15px);
    }
  }

  .ant-form-item-control {
    input {
      margin-left: rem(15);
      width: rem(300);

      @media (max-width: $container-lg) {
        width: 100%;
        margin-left: 0;
      }
    }

    button {
      padding: 0 rem(25);

      @media (max-width: $container-lg) {
        margin-bottom: 0.3rem;
      }
    }
  }

  &-feedback {
    position: absolute;
    width: fit-content;

    @media (max-width: $container-lg) {
      top: -0.5rem;
    }
  }
}

.activate-property-success {
  width: 50%;

  @media (max-width: $container-xlg) {
    width: 100%;
  }
}

.activate-property-info {
  margin-bottom: 24px;
  background: white;
  padding: 0 38px;

  .description {
    padding-left: 10px;

    h4 {
      margin-bottom: 0;
      margin-top: 10px;
    }

    p {
      margin: 0;
      font-size: $base-font-size;
      line-height: $base-font-size + 6px;
      display: flex;

      i.anticon-check {
        color: $progress-color;
      }

      svg {
        width: 10px;
        margin-right: 0.3rem;
        margin-top: 0.2rem;
      }

      a {
        text-decoration: underline;
        color: map-get($color, "DeepSkyBlue");

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  &__top {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    margin-top: 0;
    padding: 0 1.5rem;

    @media (max-width: $container-xxlg) {
      flex-direction: column;
    }

    &-left {
      p {
        margin: 0;
        font-size: $base-font-size;
        line-height: $base-font-size + 6px;

        i.anticon-check {
          color: $progress-color;
        }

        svg {
          width: 10px;
        }

        a {
          text-decoration: underline;
          color: map-get($color, "DeepSkyBlue");

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    @media (max-width: $container-xxlg) {
      &-right {
        margin-top: 2rem;
      }
    }

    @media (min-width: $container-xxxlg) {
      &-left {
        width: 60%;
      }

      &-right {
        width: 35%;
        margin-top: 2rem;
      }
    }
  }

  &__billing-card {
    background-color: #f4f4f4;
    border-radius: 1rem;
    width: fit-content;

    &-label-wrapper {
      padding: 1rem 1.2rem;
      display: flex;
    }

    .operator {
      display: flex;
      align-items: center;
      padding: 0 2rem 0 0;
      font-size: $h2-font-size;
      font-weight: $font-weight-medium;
    }

    &-account-wrapper {
      background-color: #eaeaea;
      padding: 1rem 1.2rem;
      border-radius: 0 0 1rem 1rem;

      @media (max-width: $container-lg) {
        padding: 1rem 0;
      }

      &-header {
        padding-bottom: 0.5rem;
        border-bottom: 3px solid #b7b7b7;

        @media (max-width: $container-xlg) {
          display: none;
        }

        h4 {
          font-size: $h4-font-size;
          text-align: center;
          margin: 0;
        }

        p {
          text-align: center;
          font-size: rem(11);
          margin: 0;
          color: #474747;
        }
      }

      &-body {
        padding: 0 1rem;

        &-item {
          display: flex;
          padding: 1rem 0;

          &.no-padding {
            padding: 0.2rem 0;
          }

          label {
            display: flex;
            align-items: center;
          }

          &-left {
            text-align: left;
            font-weight: $font-weight-medium;
            width: 13%;
            padding-left: 1.5rem;

            &.large {
              width: 25%;
              padding-left: 0;
            }
          }

          &-right {
            width: 60%;

            strong {
              margin-right: 1rem;
            }
          }

          border-bottom: 2px solid #b7b7b7;
        }
      }

      button {
        margin-top: 1rem;
      }
    }
  }

  &__body {
    display: flex;
    justify-content: space-between;
    padding: 0 1.5rem;
    margin-top: 1rem;

    .anticon.anticon-loading {
      margin-right: 1rem;
    }
  }

  &__left {
    width: 50%;

    button {
      margin-top: 30px;
      margin-left: 10px;
    }

    &-terms-wrapper {
      margin-top: 1rem !important;

      label {
        margin-top: 0.2rem;
      }

      &-text {
        padding-left: 2rem;
      }
    }
  }
}

.activate-property-info__right-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  margin-right: rem(30);

  &:last-child {
    margin-right: 0;
  }

  &__body {
    background-color: #e6e6e6;
    padding: 15px;
    border-radius: 3px;
    margin: 8px 0 4px 0;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-width: 150px;

    span {
      font-weight: $font-weight-medium;
      font-size: $h2-font-size;
    }

    del {
      font-size: $h3-font-size;
    }

    table {
      margin: 0 15px;

      tr {
        border-bottom: 2px solid #bbbbbb;

        &:nth-child(2) {
          border-bottom: 3px solid #bbbbbb;
        }

        &:last-child {
          border-bottom: none;
          font-weight: $font-weight-medium;
        }

        td {
          padding: 3px 10px 3px 18px;
          text-align: left;
          font-size: $h4-font-size;

          &:nth-child(2) {
            border-left: 2px solid #bbbbbb;
          }
        }
      }
    }
  }
}

.activate-property-info-mobile {
  margin-bottom: 24px;
  background: white;

  h3 {
    font-size: $h4-font-size;
  }

  span {
    display: block;
    color: $dark-text;
  }

  &__reward-amounts,
  &__total-amount,
  &__service-amounts,
  &__total-service-amount {
    padding: 8px 16px;
    border-top: 2px solid #cccccc;
    font-size: 16px;
    display: flex;
    align-items: center;
    font-weight: $font-weight-medium;
    justify-content: space-between;
  }

  &__total-amount,
  &__total-service-amount {
    border-bottom: 2px solid #cccccc;
    font-size: $h4-font-size;
    font-weight: $font-weight-medium;
  }

  .total-figure {
    display: flex;
    align-items: baseline;
  }

  .subscription-type {
    font-weight: 300;
    font-size: 14px;
    margin-left: 8px;

    &.service {
      width: 50px;
    }
  }

  &__payment-method {
    background-color: #eaeaea;

    p {
      padding: 16px 16px 0;
      margin-bottom: 0;
    }

    button span {
      color: $white;
    }
  }

  &__service-card {
    margin-top: 32px;
    background-color: #f4f4f4;

    .title {
      font-size: $h4-font-size;
      font-weight: $font-weight-medium;
      padding: 16px;
    }

    .amounts-container {
      display: flex;
      align-items: baseline;

      .previous-amount {
        text-decoration: line-through;
      }

      .amount {
        font-size: $h3-font-size;
        margin-left: 8px;
      }
    }

    .service-meta {
      text-align: right;
      color: $light-text;
      padding-bottom: 10px;
      padding-right: 26px;
      padding-top: 10px;
    }
  }

  &__description {
    padding: 16px;

    &.no-top-padding {
      padding-top: 0px;
    }

    h4 {
      margin-bottom: 0;
      margin-top: 10px;
    }

    p {
      margin: 0;
      font-size: $base-font-size;
      line-height: $base-font-size + 6px;
      display: flex;

      i.anticon-check {
        color: $progress-color;
      }

      svg {
        width: 10px;
        margin-right: 0.3rem;
        margin-top: 0.2rem;
      }

      a {
        text-decoration: underline;
        color: map-get($color, "DeepSkyBlue");

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  &__activate-button {
    margin: 16px;

    &.ant-btn-primary {
      .anticon-loading {
        margin-right: 16px;
      }

      span {
        color: $white;
        display: inline;
      }
    }
  }
}

.invite-residents {
  display: flex;
  padding: 0 32px;

  @media (max-width: $container-xlg) {
    flex-direction: column;
  }

  @media (max-width: $container-lg) {
    padding: 0 16px;
  }

  &__info {
    width: calc(100% - 406px);
    box-sizing: border-box;

    @media (max-width: $container-xlg) {
      width: 100%;
    }

    p {
      width: 65%;
      display: flex;

      @media (max-width: $container-xlg) {
        width: 85%;
      }

      @media (max-width: $container-lg) {
        width: 100%;
      }

      .custom_checkbox {
        margin-right: 30px;
        margin-top: 4px;
      }

      .custom_checkbox input:checked ~ .custom_checkbox--check .custom_checkbox--check-display {
        background-color: #6c6c6c;
      }

      .ant-checkbox-wrapper {
        margin-right: rem(10px);
        margin-top: rem(5px);
      }
    }

    button {
      margin-bottom: rem(16px);
    }

    &-content-suggestion {
      h3 {
        margin: rem(20px) 0;
      }

      &__wrapper {
        display: flex;
        width: 100%;
        box-sizing: border-box;

        &-code {
          width: 80%;
          margin-right: rem(10px);

          p {
            width: 100%;
            padding: rem(10px) rem(20px);
            background-color: #f2f2f2;
            border-radius: rem(4px);
          }
        }

        &-copy {
          pre:hover {
            cursor: pointer;
          }

          &-feedback i {
            margin-right: rem(5px);
          }
        }
      }
    }
  }
}

.invite-residents__pdf-preview {
  background-color: #e5e5e5;
  border-radius: 10px;
  width: 356px;
  height: 400px;
  padding: 14px;
  overflow-y: auto;

  @media (max-width: $container-xlg) {
    height: auto;
    margin-bottom: 20px;
    width: 100%;
  }

  &__wrapper {
    width: auto;
    margin-right: 50px;

    @media (max-width: $container-xlg) {
      width: 100%;
      height: auto;
    }
  }

  &__doc {
    width: 100%;
    background-color: #1a4644;
    height: auto;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    display: flex;
    flex-wrap: wrap;
  }

  &__header {
    display: flex;
    background-color: #fff;
    align-items: center;
    width: 100%;

    &__siderbar {
      min-width: 4px;
      height: 50px;
      background-color: #bcbdbf;
    }

    &__text-logo-wrapper {
      flex: 1;
      flex-direction: column;
      justify-content: center;
      align-self: flex-end;
      margin-left: 10px;
      margin-bottom: 5px;
      position: relative;

      &__text {
        color: #6d6e70;
        font-size: 8px;
        font-family: "Source Sans Pro", sans-serif;
        font-weight: 300;
        margin-bottom: 6px;
        position: absolute;
        bottom: 30px;
        z-index: 1;
        text-transform: capitalize;
      }

      &__underline {
        width: 40px;
        background-color: #6d6e70;
        height: 1px;
        opacity: 0.5;
        display: block;
        margin-top: 4px;
      }

      img {
        width: 135px;
        height: 42px;
        position: absolute;
        bottom: -5px;
      }
    }

    &__rings-wrapper {
      padding: 12px 5px;
      background-color: #f3f3f4;

      img {
        width: 65px;
        height: 50px;
      }
    }
  }

  &__info-section {
    width: 50%;
    position: relative;
    height: 185px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: $container-xlg) {
      height: 230px;
    }

    &.sign-up {
      background-color: #d56a4d;
    }

    &.text-bubble {
      background-color: #56c6cc;
    }

    &.make-money {
      background-color: #7cb68c;
    }

    img.sign-up {
      position: absolute;
      width: 60%;
      top: -12px;

      @media (max-width: $container-sm) {
        top: -3px;
      }
    }

    img.text-bubble {
      position: absolute;
      width: 75%;
      top: -30px;

      @media (max-width: $container-sm) {
        top: -10px;
      }
    }

    img.make-money {
      position: absolute;
      width: 42%;
      top: 3px;

      @media (max-width: $container-sm) {
        top: 8px;
      }
    }

    &__description {
      display: flex;
      position: absolute;
      top: 70px;
      width: 100%;
      padding: 0px 10px;

      @media (max-width: $container-xlg) {
        top: 140px;
      }

      @media (max-width: $container-sm) {
        top: 70px;
      }

      &__number {
        font-family: superclarendon, serif;
        font-style: normal;
        font-weight: 900;
        color: #fff;
        font-size: 18px;
      }
    }

    &__text-wrapper {
      display: flex;
      flex-direction: column;
      padding-left: 5px;
      color: #fff;

      &__title {
        font-size: 10px;
        font-family: "Source Sans Pro", sans-serif;
        font-weight: 700;
        color: #000;
      }

      &__sub-title {
        font-size: 10px;
        font-family: "Source Sans Pro", sans-serif;
        font-weight: 600;
      }

      &__underline {
        width: 25%;
        height: 1px;
        background-color: #000;
        opacity: 0.3;
        margin: 5px 0px;
      }

      &__description {
        font-size: 7px;
        font-family: "Source Sans Pro", sans-serif;
        font-weight: 400;
      }
    }
  }

  &__reward-section {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding: 10px 0;

    &__sub-title {
      font-size: 8px;
      font-family: "Source Sans Pro", sans-serif;
      font-weight: 600;
      width: 40%;
      text-align: center;
    }

    &__amount {
      font-size: 22px;
      font-family: "Source Sans Pro", sans-serif;
      font-weight: 600;
      text-align: center;
    }

    &__underline {
      width: 45%;
      height: 1px;
      background-color: #000;
      opacity: 0.7;
      margin: 5px 0px;
    }

    &__description {
      font-size: 8px;
      font-family: "Source Sans Pro", sans-serif;
      font-weight: 500;
      width: 85%;
    }

    img {
      width: 70px;
      height: 25px;
      margin-top: 15px;
    }

    &__link {
      font-size: 8px;
      font-family: "Source Sans Pro", sans-serif;
      font-weight: 400;
      margin-top: 15px;
    }
  }

  &__footer {
    font-size: 8px;
    color: #eff1f1;
    padding: 5px;
    line-height: 1.2;
    width: 100%;
  }

  &__cta-wrapper {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    position: relative;

    @media (max-width: $container-lg) {
      margin-bottom: 20px;
      justify-content: flex-start;
    }

    .pdf-download-feedback {
      position: absolute;
      right: -20px;
      bottom: 3px;
      transition: opacity 0.3s linear;
    }
  }
}

.schedule-kick-off {
  margin-left: 12%;

  @media (max-width: $container-lg) {
    margin-left: 0;
  }

  button {
    margin-left: 30px;
  }
}

.setup-property {
  padding: 70px 5px 5px 50px;
  position: relative;

  @media (max-width: $container-xlg) {
    padding: 70px 5px 5px 25px;
  }

  @media (max-width: $container-lg) {
    padding: 50px 5px 5px 5px;
  }

  @media (max-width: $container-sm) {
    padding: 100px 5px 5px 5px;
  }

  &__description {
    position: absolute;
    top: -8px;
    width: 60%;

    @media (max-width: $container-xlg) {
      width: 100%;
    }
  }

  &__section-header {
    display: flex;
    justify-content: space-between;
    margin: 35px 0 20px 0;

    @media (max-width: $container-sm) {
      flex-direction: column;
    }

    &.messenger-header {
      margin-top: 65px;
    }
  }
}

.divider {
  width: 130%;
  height: 4px;
  background-color: #e8e8e8;
  position: absolute;
  left: -20px;
  right: -10px;
}

.completed-section {
  display: flex;
  padding: 25px 40px;
  background-color: #fff;
  margin-top: 20px;
  position: relative;

  &__description {
    width: 50%;

    @media (max-width: $container-lg) {
      width: 100%;
    }
  }

  .anticon {
    position: absolute;
    left: 17px;
    top: 30px;
  }
}
