.propertyinfo {
  margin-top: 2em;

  &__header-text{
    line-height: 2em;
    font-size: 14px;

    h5 {
      margin-right: 0.5em;
    }
  }

  &__links{
    display: flex;
    justify-content: flex-end;
    align-items: center;

    h4{
      margin-right: 20px;
    }
  }

  &__danger-area {
    background-color: #8d2403;
    width: 100%;
    margin: 10px 0px;
    padding: 15px;
    border-radius: 10px;
  }
  .danger {
    color: $white;
  }

  &__update-button{
    padding: 0 15px !important;
    margin-top: 10px;
    float: right;
    font-size: 14px;
    text-align: center;

    @media (max-width: $container-md) {
      float: none;
    }
  }

  &__details-wrapper{
    display: flex;
    margin-top: 3%;


    @media (max-width: $container-lg) {
      flex-direction: column;
    }
  }

  &__header-info{
    width: 40%;
    margin-top: 0 !important;

    @media (max-width: $container-lg) {
      flex-direction: column;
      align-items: flex-start !important;
    }
  }

  &__subheader {
    display: flex;
    margin-top: 1em;

    &-left {
      width: 60%;
    }

    &-right {
      width: 40%;
      margin-top: 1.2em;
    }
  }

  .website {
    width: 80%;
    overflow: hidden;
  }

  &__ctas {
    width: 260px;
    display: flex;
    justify-content: flex-end;
  }

  &__cta {
    margin: 1em 0;
    width: 200px;
  }

  &__form {
    &-text {
      margin: 0;
      border-radius: 0;
      display: flex;

      h5 {
        margin-right: 0.5em;
        margin-top: 0.5em;
      }

      input {
        border: 1px solid #000000;
        border-radius: 0 !important;
        line-height: 0 !important;
        margin-bottom: 0 !important;
        width: 250px;
      }
    }

    &-prompt {
      width: 5px;
      display: flex;
      justify-content: flex-end;
    }
  }

  &__header {
    background:$white;
    padding: 2em;

    &-info {
      display: flex;
      margin-top: 1em;
      align-items: center;
    }

    &-text {
      display: flex;
      margin-right: 1em;

      h4 {
        margin-right: 0.5em;
      }
    }

    &-referral {
      margin-left: 10px;
      color: $dark-text;
      text-decoration: underline;
    }

    &-referralcta {
      background: none !important;
      border: none;
      border-radius: 0;
      padding: 0 !important;
      color: $dark-text;
      border-bottom: 1px solid $dark-text;
      cursor: pointer;
      margin-left: 1em;

      &.only-cta {
        margin-left: 0;
      }
    }
  }

  &__content {
    background:$white;
    padding: 2em;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  &__table {
    background: $white;
    &-row:hover:not(.ant-table-expanded-row) > td {
      background-color: $white !important;
    }
  }

  &__carousel {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__left-arrow {
    color: $white;
    background: $black;
    font-size: 2em;
    line-height: 55px;
    height: 50px;
    opacity: 0.7;
    z-index: 2;
    position: absolute;
    left: 0;
  }

  &__right-arrow {
    color: $white;
    background: $black;
    font-size: 2em;
    line-height: 55px;
    height: 50px;
    opacity: 0.7;
    z-index: 2;
    position: absolute;
    right: 0;
  }

  &__input-link{
    width: 250px;
  }

  &__details{
    width: 50%;

    @media (max-width: $container-lg) {
      width: 100%;
      margin-bottom: 1em;
    }
  }

  &__social-media-links {
    width: 50%;
    float: right;
  }
}

.ant-carousel {
  width: 100%;
}

.ant-carousel .slick-slide {
  text-align: center;
  height: 450px;
  width: 80%;
  overflow: hidden;
}

.ant-carousel .slick-slide h3 {
  color: $black;
}

.ant-carousel .slick-slide img {
  width: 99%;

  &:first-child {
    margin-right: 2em;
  }
}

.slick-prev, .slick-next {
  font-size: 15px !important;
}

.slick-prev:before, .slick-next:before  {
  content: '' !important;
}

.slick-slide {
  height: 450px;
  div {
    height: 100%;
    width: 100%;
    display: flex;
    img {
      object-fit: cover;
      margin-right: 0;
    }
  }

  & ~ .slick-active ~ .slick-active {
    img {
      width: 100% !important;
    }
  }
}
