.referral-action-window {
  position: absolute;
  width: calc(100vw - 200px);
  margin: -1.67em -2em 0 -2.34em;
  background: #e8e8e8;
  z-index: 3;
  height: calc(100% - 65px);
  min-height: calc(100vh - 65px);
}

.referral {
  width: 100%;

  &__header {
    &-wrapper {
      background: $white;

      button {
        padding: 0 2rem;
      }
    }

    &-content-wrapper {
      padding: 1em 2.5% 2.5%;
    }

    &-content {
      display: flex;
    }

    &-left {
      margin-right: 2rem;

      &-image {
        width: 120px;
        height: 120px;
        border-radius: 4px;
      }
    }
  }

  &__body {
    background: $white;
    padding: 2.5rem 0;

    &-wrapper {
      padding: 2rem 2.5rem 10rem 2.5rem;
    }
  }

  &__content {
    width: 80%;
    margin: auto;
    margin-bottom: 3rem;
  }
}

.billing-window-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 350px);
  background: $white;
}
