.invoice-form-wrapper, .address-form-wrapper {
    width: 100%;
}

.stripe-form-wrapper .invoice-form {
  margin-top: unset;
}

.stripe-form-wrapper .stripe-invoice-form {
  margin-top: unset;
}

.invoice-form {
  width: 50%;
  margin: auto;
  position: relative;

  &__bill-to {
    font-size: 16px;
  }

  @media (max-width: $container-xlg) {
    min-width: 80%;
  }

  .ant-form-item {
    &:last-child {
      margin-bottom: 1rem;
    }
  }

  .ant-form-item-label {
    width: 100%;
    line-height: normal;
    margin-top: 0.3rem;

    label {
      margin: 0.3rem 0;
      text-align: left;
      width: 100%;
      display: -webkit-flex;
      display: flex;
      font-size: 0.71429rem;
      color: #808080;
      font-weight: 600;

      &::before {
        content: "";
        display: none;
      }
    }
  }

  input,
  .ant-select-selection {
    background-color: white;
    border-radius: 2px;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    border-color: transparent;
  }
}

.stripe-invoice-form {
  width: 100%;
  margin: auto;
  position: relative;

  &__bill-to {
    font-size: 16px;
  }

  @media (max-width: $container-xlg) {
    min-width: 80%;
  }

  .ant-form-item {
    &:last-child {
      margin-bottom: 1rem;
    }
  }

  .ant-form-item-label {
    width: 100%;
    line-height: normal;
    margin-top: 0.3rem;

    label {
      margin: 0.3rem 0;
      text-align: left;
      width: 100%;
      display: -webkit-flex;
      display: flex;
      font-size: 0.71429rem;
      color: #808080;
      font-weight: 600;

      &::before {
        content: "";
        display: none;
      }
    }
  }

  input,
  .ant-select-selection {
    background-color: white;
    border-radius: 2px;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    border-color: transparent;
  }
}

.stripe-invoice-form {
  button {
    background-color: #32305d;
    border-color: #32305d;
    padding: 0 2rem;
    font-weight: $font-weight-medium;
    margin-top: 2rem;
  }

  &__errors {
    margin-top: 1rem;
    width: 100%;
    color: $light-text;
    font-weight: $font-weight-medium;
    color: $indian-red;
    text-align: center;
  }
}

.invoice-form {
  button {
    background-color: #32305d;
    border-color: #32305d;
    padding: 0 2rem;
    font-weight: $font-weight-medium;
    margin-top: 2rem;
  }

  &__errors {
    margin-top: 1rem;
    width: 100%;
    color: $light-text;
    font-weight: $font-weight-medium;
    color: $indian-red;
    text-align: center;
  }
}
.stripe-form-wrapper .billing-address-form  {
  display: none;
}
.stripe-form-wrapper.validate_address .billing-address-form {
  display: block;
}

.stripe-form-wrapper.validate_address .stripe-billing-address-form {
  display: block;
}

.stripe-form-wrapper.validate_address .invoice-form {
  display: none;
}

.stripe-form-wrapper.validate_address .stripe-invoice-form {
  display: none;
}

.billing-address-form {
  width: 50%;
  margin: auto;
  position: relative;
  text-align: left;

  button {
    background-color: #32305d;
    border-color: #32305d;
    padding: 0 2rem;
    font-weight: $font-weight-medium;
    margin-top: 2rem;
  }
  
  button.ant-btn-text {
    background-color: unset;
    border: unset;
    color: $light-text;
  }

  .billing-address-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 3vw;

    &__suggested {
      white-space:pre-line;
      width: 50%;
    }

    &__entered {
      white-space:pre-line;
      width: 50%;
    }
  }
}