.actionwindow {
  min-height: 90vh !important;
  
  @media (max-width: $container-xxxlg) {
    margin: 5px;
    width: 100%;
  }

  @media (min-width: 1442px) {
    margin: 5px;
    width: 100%;
  }

  .add-property {
    padding: 2.5% 0px;
    background-color: white;
    flex-direction: column;
    display: flex;

    &__header { 
      padding: 0px 2.5%;
      width: 100%;
      display: flex;
      justify-content: space-between;

      Button {
        background-color: map-get($color, "light-gray");
        border-color: map-get($color, "light-gray");

        :hover, :focus {
          background-color: map-get($color, "light-gray");
          border-color: map-get($color, "light-gray");
        }
      }
    }

    &__content {
      border-style: dashed;
      border-color: #cdcdcd;
      border-width: 2px;
      margin: 20px;
      border-radius: 5px;

      &__add-instruction {
        padding: 20px;
        font-size: 13px;
        text-align: center;
      }

      .ant-tabs-nav-wrap {
        margin-left: 0px !important;

        .ant-tabs-nav-scroll {
          background: #f2f2f2;
          border-top: 2px solid #d9d9d9;
          border-bottom: 3px solid #d9d9d9;
          height: 80px;
          
          .ant-tabs-nav, .ant-tabs-nav-animated {
            position: unset;
            width: 100%;

            div {
              display: flex;
              justify-content: space-evenly;
              width: 100%;
            }
          
            .ant-tabs-ink-bar, .ant-tabs-ink-bar-animated {
              display: flex;
              justify-content: space-evenly;
              background: black;
              height: 2.5px;
            }

            .ant-tabs-tab {
              color: map-get($color, "light-gray");

              :hover {
                color: map-get($color, "light-gray");
              }

              span {
                text-align: center;

                img {
                  width: 100px;
                  height: 20px;
                  margin-bottom: 10px
                }

                i {
                  margin-right: unset;
                  margin-bottom: 10px;

                  svg {
                    width: 1.5em !important;
                    height: 1.5em !important;
                  }
                }
              }
            }
            .ant-tabs-tab-active {
              .text {
                color: map-get($color, "black");
              }
              i { 
                color: black
              }
            }
          }
        }
      }

      &__add-procedure-header {
        padding-top: 20px;
        font-weight: bold;
        font-size: 17px;
        padding-bottom: 1%;
      }

      &__add-procedure-steps {
        font-size: 13px;
        line-height: 23px;
        color: map-get($color, "gray");
        padding-bottom: 2.5%;
      }

      &__need-help {
        padding-bottom: 2.5%;
        width: 100%;
        display: flex;
        justify-content: space-between;

        
        &-column {
          width: 100%;
          
          p {
            font-weight: bold;
          }
        }

        @media screen and (max-width: 600px) {
          flex-direction: column;

          &-column {
            padding: 2.5% 0px;
          }
        }

        &-text {
          font-size: 13px;
          color: map-get($color, "gray");

          a {
            color: map-get($color, "rentgrata-blue");
          }
        }
      }
    }
  }
}
