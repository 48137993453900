.messenger {
  background: $white;
  color: $black;
  margin-top: 2em;
  padding: 2em;

  &__images {
    margin-bottom: 20px;
  }

  &__header {
    display: block;
  }

  .ant-btn {
    height: auto;
    font-size: 1.125rem;
    line-height: 110%;
    padding: 10px 20px;
  }

  .ant-switch-checked {
    background-color: rgb(125, 224, 164);
  }

  button[button-state="posted"] {
    background-color: #79E1A2;
    border-color: #79E1A2;
    color: white;
  }

  &__title {
    display: inline-block;

    &-demo-mode {
      display: inline-block;
      margin-left: 60px;
      margin-right: 10px;

      &-demo-button {
        margin-left: 10px;
      }

      &-info-icon {
        display: inline-block;
        margin-left: 10px;

        &-content-panel {
          width: 200px;
          height: 250px;
        }
      }
    }
  }

  &__images {
    display: flex;
    flex-direction: column;

    img {
      width: 100%;
      margin-right: 2em;
    }

    p {
      margin-top: 1em;
    }

    @media (min-width: $container-md) {
      img {
        width: 100%;
        margin-right: 2em;
      }
    }
  }

  &__table {
    background: $white;

    &-row:hover:not(.ant-table-expanded-row) > td {
      background-color: white !important;
    }
  }

  &__guide {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 1.2em;
    flex-wrap: wrap;

    h2 {
      font-size: 2rem;
      color: #262626;
      line-height: 110%;
      font-weight: 600;
    }

    h4 {
      font-size: 1.12rem;
      color: #404040;
      font-weight: normal;
    }

    @media (min-width: $container-md) {
      flex-direction: row;
    }

    &-installation {
      display: grid;
      grid-template-columns: repeat(2, minmax(100px, 1fr));
      grid-template-rows: 1fr;
      gap: 0px 2vw;
      grid-template-areas:
        ". .";
    }

    &-left {
      width: 100%;

      @media (min-width: $container-md) {
        width: 100%;
      }

      &-section {
        margin-bottom: 1.2em;

        h4 {
          margin-bottom: 2rem;

          &.input-wrapper {
            margin-bottom: 0.6rem;
          }
        }

        &-description {
          p {
            margin-bottom: 0;
          }

          span {
            color: $light-text;
          }
        }

        &-col {
          display: flex;
          flex-direction: column;
          margin-bottom: 2rem;

          h3 {
            margin-bottom: 0;
          }

          input {
            max-width: 150px;
          }

          &-subtitle {
            color: $light-text;
          }

          &-row {
            display: flex;
            align-items: center;
            gap: 1rem;

            span {
              color: $light-text;
            }
          }
        }

        &-position {
          margin-top: 1em;

          &-header {
            color: #A5A7A8;
          }

          input {
            width: 60px;
            border: 2px solid #d5d5d5;
            font-size: 14px;
            box-sizing: border-box;
            margin: 0 .8rem;
          }

          span {
            color: #878787;
            font-weight: 300;
            margin-left: 0.3em;
            font-size: .85rem;
          }
        }

        &-position-input-wrapper {
          display: flex;
          margin-bottom: .7rem;

          h4 {
            margin-top: 0;

            &:nth-child(2) {
              margin-left: 1rem;
            }
          }
        }

        &-url-input,
        &-gtm-input {
          width: 100%;
          box-sizing: border-box;

          .input__wrapper {
            box-sizing: border-box;
          }

          input {
            width: 90%;
            border: 2px solid #d5d5d5;
            font-size: 14px;
            box-sizing: border-box;
          }
        }


        button.apply {
          margin: 1.5rem 0;
          background-color: $light-gray;
          color: $white;
          box-sizing: border-box;
          font-size: 15px;
          font-weight: 600;

          &:hover {
            border-color: $dark-gray;
            background-image: linear-gradient($light-gray-grad, $light-gray);
          }
        }
      }

      &-default-switch {
        margin: 0px 10px;
        background-color: #E4E4E4;
        border: 1px solid #CED0D1;

        &::after {
          background-color: #A5A7A8 !important;
        }

        &.ant-switch-checked {
          background-color: #242626 !important;
          border: 1px solid #E4E4E4;

          &::after {
            background-color: #C3C3C3 !important;
          }
        }
      }

      &-theme-switch {
        margin: 0px 10px;
        background-color: #E4E4E4;
        border: 1px solid #CED0D1;

        &::after {
          background-color: #A5A7A8 !important;
        }

        &.ant-switch-checked {
          background-color: #242626 !important;
          border: 1px solid #E4E4E4;

          &::after {
            background-color: #C3C3C3 !important;
          }
        }
      }

      &-position-switch {
        margin: 0px 10px;
        background-color: #E4E4E4;
        border: 1px solid #CED0D1;

        &::after {
          background-color: #A5A7A8 !important;
        }
      }
    }

    &-right {
      width: 100%;

      @media (min-width: $container-md) {
        width: 100%;
      }
    }

    &-knock {
      margin-bottom: 1.2em;
      margin-top: 1.2em;

      label {
        text-align: left;
        font: normal normal 600 17px/22px Source Sans Pro;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        display: block;
        padding: 14px 0;
      }

      &-images {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
      }

      &-images img {
        width: 30%;
      }

      &-images img.arrow-right {
        max-height: 26px;
        max-width: 100px;
      }

      &-configuration {
        display: none;
        padding: 0 0 2vw;

        &-active {
          display: block;

          hr {
            width: 250px;
            margin-left: 0;
            margin-right: auto;
          }
        }
      }

      p {
        color: #696969;
      }

      &-default-switch {
        margin: 0px 20px 0 0;
        background-color: #E4E4E4;
        border: 1px solid #CED0D1;

        &::after {
          background-color: #A5A7A8 !important;
        }

        &.ant-switch-checked {
          background-color: map-get($color, "rentgrata-blue") !important;
          border: 1px solid #E4E4E4;

          &::after {
            background-color: white !important;
          }
        }
      }

      &-showcase {
        margin-top: 2.5em;

        &-options {
          display: flex;
          margin-bottom: 40px;

          label {
            font-weight: normal;
            text-align: left;
            font: normal normal normal 18px/23px Source Sans Pro;
            letter-spacing: 0px;
            color: #262626;
            opacity: 1;
          }

          &-select {
            width: 45%;
            counter-reset: item-counter;
            padding: 10px 0;
          }

          img {
            width: 30%;
          }

          .ant-select {
            display: flex;
            gap: 10px;
            margin-bottom: 22px;
            align-items: center;

            .ant-select-selection {
              width: 80%;
            }
          }

          .ant-select::before {
            counter-increment: item-counter;
            content: counter(item-counter);
            display: block;
            color: map-get($color, "black-600");
            font-weight: 600;
            font-size: 16px;
          }
        }
      }


    }

    &-dedicated-config {
      width: 100%;

      @media (min-width: $container-md) {
        width: 100%;
      }

      .shortlink {
        color: $dark-gray;
        padding-bottom: 6px;
      }

      .logo-error-text {
        margin: 0 12px;
        color: $dark-gray;
      }
    }

    &-dedicated-config-row {
      display: flex;
      width: 100%;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      padding: 1vw 0;

      .ant-btn {
        height: 32px;
        font-size: 14px;
        line-height: 1.499;
        padding: 0 15px;
      }

      a.ant-btn {
        padding-top: 5px !important;
      }

      .avatar-uploader {
        margin: 0 14px;
      }

      .goto-url {
        margin-left: 12px;
      }


      span.avatar-uploader .ant-upload.ant-upload-select {
        border: none;
        background-color: inherit;
      }
    }
  }

  &__send-insllation-instructions {
    display: inline-flex;

    button {
      padding: 6px !important;
    }
  }
}
