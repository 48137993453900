.actionwindow {

  .disable-reward {
    min-height: 90vh !important;
  
    @media (max-width: $container-xxxlg) {
      width: 100%;
    }
  
    @media (min-width: 1442px) {
      width: 100%;
    }

    &.property-rewards {
      width: calc(100vw - 200px);
      margin-left: -35px;
      padding: 0px 24px;
      background: #e8e8e8;

      @media (max-width: $container-lg) {
        width: 100vw;
        padding: unset;
      }
    }
  
    &__header {
      background: $white;
      padding: 1em;
      margin: -25px -23px 0 -33px;
      min-height: 300px;
    
      &-top {
        padding: 0px 20px;
      }
    
      &-bio {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        margin-bottom: 20px;

        h3 {
          margin-left: 20px;
        }
        .ant-row{
          margin-left: 40px;
          margin-bottom: 20px;
        }
      }
    }
  
    &__content {
      margin-top: 2em;
      padding: 2em;
      background: $white;

      &-body {
        margin: 5px 40px 0 40px;
        font-size: medium;

        .ant-form-horizontal, .ant-radio-group {
          display: flex;
          flex-direction: column;

          .ant-form-item-control {
            width: 60%;

            @media (max-width: $container-xlg) {
              width: 100%;
            }
          }

        }

        .ant-checkbox-wrapper {
          border-color:  #49D0D4 !important;
          margin-left: unset;
        }

        .ant-checkbox-inner {
          margin-left: unset;
        }

        .ant-form-explain {
          display: flex;
          justify-content: flex-end
        }

        .cancel-button {
          margin-left: 7px;
        }

        .disable-button {
          background-color: crimson;
          color: white;
        }
      }
    }
  }
}

