.util{

  &__sort-button {
    cursor: pointer;
    text-decoration: underline;
    background-color: transparent;
    border: none;
    color: rgba(0, 0, 0, 0.65);
    text-decoration: none;
    font-weight: 600;
  }

  &__hide-column {
    display: none;
  }

  &__invisible-column span {
    visibility: hidden;
  }

  &__mark-rewarded-button {
    border-color: map-get($color, "rentgrata-blue");
    color: #1C1B1F !important;

    &:hover {
      color: map-get($color, "rentgrata-blue");
      border-color: map-get($color, "rentgrata-blue");
    }
  }

  &__rewarded {
    background-color: #A4D6D5;
    width: 103px;
    height: 28px;
    margin: 0 0 0 -6px;
  }

  &__rewarded-icon {
    margin: 8px -5px 10px 10px;
  }

  &__rewarded-button {
    width: 123px;
    padding: 0 !important;
    line-height: 2.1 !important;

    &-prospect {
      width: 123px;
      padding: 0 !important;
      line-height: 2.2 !important;
    }

    &:hover {
      background-color: map-get($color, "rentgrata-blue");
    }
  }

  &__dropdown {
    &-style{
      box-shadow: 1px 1px 5px #a7a7a7
    }
    &-button:hover{
      color: $theme-primary;
    }
    &-button-cancel {
      color: red;
    }
    &-button-cancel:hover{
      color: #c00000;
    }
  }

  &__disable-reward-button {
    display: flex;
    width: 30px;
    justify-content: space-evenly;
    align-items: center;
    right: 2rem;

    @media (max-width: $container-lg) {
      margin-left: 60px;
    }

    &:hover {
      cursor: pointer;
      background-color: #e6e6e6;
    }

    &-ellipsis {
      height: 30px;
      width: 30px;
      border-radius: 5px;
      display: flex;
      justify-content:space-evenly;
      align-items: center;
      cursor: pointer;

      &:hover {
        background-color: #e8e8e8;
      }


      span {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #666666;
        display: inline-block;

      }
    }

    &-enable {
      color: #4acfd4 !important;
    }

    &-disable {
      color: #c00000 !important;;
    }

    &-disabled {
      color: #666666 !important;
    }
  }

  &__green {
    color: #4bbc4b!important;
  }

  &__gray {
    color: #2C2C2C!important;
  }

  .ant-tooltip-inner {
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #707070;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0px;
    color: #464646;
  }

  &.rg-highlight {
    color: #49D0D4;
    transform: rotate(35deg);
    display: inline-block;
    position: absolute;
    top: -5px;
    font-weight: 600;
    font-size: 16px;
  }
}
.ant-tooltip-inner {
  color: #FFFFFF;
  background: rgba(28, 27, 31, 0.9);
  border-radius: 6px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #707070;
  font-size: 12px;
  letter-spacing: 0px;
}

.ant-tooltip-arrow {
  display: none;
}

.ant-btn-success, .ant-btn-success:hover, .ant-btn-success:focus {
  background-color: #49D49F;
  color: #000000a6;
  outline: 0;
}

.ant-btn-success:hover {
  border-color: #000000a6;
}
.off-white-bg {
  background: #fcfcfc;
}
