.conversations {
  &__table {
    background: $white;
    padding: 1em;

    &-row:hover:not(.ant-table-expanded-row) > td {
      background-color: white !important;
    }
  }
}
