@import "colors";

$black                   : map-get($color, "black");
$white                   : map-get($color, "white");
$red                     : map-get($color, "red");
$dark-gray               : map-get($color, "dark-gray");
$dim-gray                : map-get($color, "dim-gray");
$light-gray              : map-get($color, "light-gray");
$light-gray-grad         : map-get($color, "light-gray-grad");
$light-green             : map-get($color, "light-green");
$dark-green              : map-get($color, "dark-green");
$light-yellow            : map-get($color, "light-yellow");
$faded-yellow            : map-get($color, "faded-yellow");

$theme-primary           : map-get($color, "rentgrata-blue");
$default-focus-bg        : map-get($color, "rentgrata-blue-focus");
$theme-background        : map-get($color, "background-gray");
$theme-default-background: map-get($color, "rentgrata-gray");

$dark-text               : map-get($color, "dark-text");
$medium-text             : map-get($color, "medium-text");
$light-text              : map-get($color, "light-text");

$button-warning          : map-get($color, "yellow");
$button-danger           : map-get($color, "rentgrata-red");
$antd-button-primary     : map-get($color, "antd-blue");

$progress-color          : map-get($color, "Emerald");
$rosy-brown              : map-get($color, "rosy-brown");
$indian-red              : map-get($color, "indian-red");
$gains-boro              : map-get($color, "gains-boro");
$antd-red                : map-get($color, "antd-red");
