.integrations {
  background: $white;
  color: $black;
  margin-top: 2em;
  padding: 2em;

  .title {
    color: $dark-text;
  }

  h1 {
    color: #262626; // move to variable
    font-size: 2rem;
  }

  .category-wrapper {
    margin-bottom: 2rem;
  }
  .item-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .item-wrapper-manager {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    .item {
      margin-bottom: 2rem;
    }
  }
  .item {
    margin-right: 2rem;

    h3 {
      margin-bottom: 0;
    }
    p {
      margin-bottom: 0;
    }
    button {
      margin-top: 0.5rem;
    }
  }

  .dot {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    display: inline-block;
    margin-right: 0.5rem;

    &.green {
      background-color: #08D600;
    }
    &.gray {
      background-color: $dark-gray;
    }
  }

  .submit-wrapper {
    .ant-form-item-children {
      display: flex;
      align-items: center;
    }
    button:disabled {
      background-color: #838383;
      color: white;
    }
    button.success:disabled {
      background-color: #79e1a2;
    }
  }
  
  form button {
    width: 100%
  }

  .add-new {
    display: flex;

    .add-new-tile {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 200px;
      border: solid 1px $dark-gray;
      border-radius: 5px;
      padding: 1rem;
      margin-right: 1rem;

      &:hover {
        cursor: pointer;
      }
  
      .plus {
        position: absolute;
        font-size: 1.5rem;
        color: $dark-text;
        right: 0.5rem;
        top: 0.5rem;
        line-height: 1rem;
      }
    }
  }

  .instructions {
    color: $medium-text;
  }

  #remove-integration-icon {
    padding-top: 3px;
    margin-right: 6px;
    margin-left: -3px;
  }
  .error {
    color: #5E120E;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .success {
    color: white;
    background-color: #79e1a2;
    border-color: #79e1a2;
  }

  .or {
    display: flex;
    align-items: center;
    text-align: center;
    color: $light-gray;
  }
  .or::before,
  .or::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid $light-gray;
  }
  .or:not(:empty)::before {
    margin-right: 1rem;
  }
  .or:not(:empty)::after {
    margin-left: 1rem;
  }

  .add-new-services-from-update-page {
    margin-top: 1rem;

    .ant-form-item-children {
      line-height: 1.5;
    }
    p {
      margin-bottom: 0.5rem;
    }
  }
}

.integrations.manager {
  margin-top: 0;
  padding: 0;
}

.ant-btn-loading i.anticon-loading {
  margin-right: 0.5rem;
}