.settings {
  padding: 24px;
  background: $white;
  min-height: 100vh;
  min-width: 100%;
  display: flex;

  @media (max-width: $container-md) {
    flex-direction: column;
  }

  &__left {
    margin-bottom: 1em;

    @media (min-width: $container-md) {
      display: block;
      margin-bottom: 0em;
    }
  }

  &__right {
    width: 100%;

    @media (min-width: $container-md) {
      width: 40%;
      margin-left: 2em;
      padding-top: 1em;

      &.integrations-manager-wrapper {
        width: 100%;
      }
    }

    @media (min-width: $container-lg) {
      width: 20%;

      &.integrations-manager-wrapper {
        width: 100%;
      }
    }

    .profile__right {
      display: flex;
      flex-direction: column;
      align-items: center;

      .avatar-wrapper {
        margin-bottom: 15px;
        height: 90px;
      }
    }
  }
}

.settings .accounts {
  padding-bottom: 4rem;
  width: 42rem;

  .card-cards-wrapper {
    width: 100%;
    margin-bottom: 2rem;
  }

  @media (max-width: $container-lg) {
    width: 100%;
  }
}
