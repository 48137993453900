.dashboard {
  position: relative;

  .children__wrapper {
    margin: 24px 16px 0;
    position: relative;

    &.children__wrapper__rewards {
      margin: 0 0 0 0;
      padding: 0 0 0 0;
    }
  }

  &__add {
    float: right;
    padding-bottom: 1em;
  }
}

.prospect-search-result {
  background: map-get($color, "white");
  padding: 15px;

  &__table {
    .guest__card-created {
      color: map-get($color, "white");
      background: map-get($color, "rentgrata-blue");
      padding: 5px 8px;
      border-radius: 3px;
    }

    .prospect {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &__name {
        font-weight: bold;
      }

      &__listing-name {
        font-size: 12px;
        margin-top: 5px;
        color: map-get($color, "dark-gray");
      }
    }
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background-color: map-get($color, "rentgrata-gray") !important;
  }

  .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    padding: 3px 10px;
  }

  &__title {
  }

  &__close {
    font-size: 1.8em;
    margin-right: 20px;
  }
}

.rewards_hero {
  border-bottom: 1px solid #e8e8e8;
  @media (min-width: $container-xxlg) {
    border-bottom: 0;
  }
}

.rewards_heroboxes .rewards_herobox {

  &:first-child {
    border-right: 1px solid #e8e8e8;
  }
}
