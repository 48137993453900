.auth {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  background: $theme-default-background;
  justify-content: center;

  a {
    color: $theme-primary;
  }

  &__logo {
    width: 1.5em;
    margin-right: 0.5em;
  }

  &__nav {
    color: $black;
    font-size: 2em;
    padding-bottom: 2em;

    a {
      color: $black;
    }
  }

  &__copyright {
    margin-right: 0.1em;

    i {
      vertical-align: -0.2em;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (min-width: $container-lg) {
      width: 100%;
    }

    &-header {
      text-align:  center;
      margin-bottom: 2em;

      h1 {
        font-size: 2em;
        margin-bottom: 0;
        color: $dark-text;
        font-weight: 600;
      }
    }
  }

  &__password {
    &-content {
      width: 300px;
    }

    &-header {
      text-align: center;

      h2 {
        font-weight: 600;
      }
    }
  }

  &__cta {
    margin: 1em 0;
    width: 100%;
  }

  &__cta-no-margin {
    width: 100%;
  }

  &__terms {
    margin-top: 1rem;
    a {
      color: #337ab7;
      &:hover {
        color: #23527c;
      }
    }
  }

  &__support {
    float: right;
    color: #CDD2D9;
  }

  &__recovery {
    display: flex;
    justify-content: space-between;
    line-height: 3em;
  }
}

.profile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &__right {
    margin-left: 5em;

    @media (max-width: $container-md) {
      margin: 1em 0em;
    }
  }

  @media (max-width: $container-md) {
    flex-direction: column-reverse;

    &__right {
      margin-left: 0em;
    }
  }
}
