html,
body {
  font-family: 'Source Sans Pro', sans-serif;
  color: $light-text;

  a {
    color: $dark-text;
  }

  a:hover {
    color: $theme-primary;
  }

  .loading {
    padding: 10em;
    margin-top: 5em;
    display: flex;
    justify-content: center;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: $font-weight-medium;
}

p {
  font-size: rem($base-font-size);
}

//ANTD Overrides
.ant-layout {
  height: auto !important;
  min-height: 100vh;
  background: $theme-background;
}

.ant-tabs-bar {
  background: $white;
}

.ant-layout-header {
  display: flex;
  justify-content: flex-end;
}

.ant-input-search {
  padding-top: 1em;
}

.ant-input:hover {
  border-color: $theme-primary;
}

.ant-input:focus {
  border-color: $theme-primary;
  box-shadow: 0 0 0 2px rgba(73, 208, 212, 0.2);
}

.ant-select-selection:hover {
  border-color: $theme-primary;
}

.ant-select-selection:focus {
  border-color: $theme-primary;
  box-shadow: 0 0 0 2px rgba(73, 208, 212, 0.2);
}

#header-user-icon {
  margin-top: 10px;
}

.ant-layout-content {
  padding: 0em 1em;
}

.ant-menu-item > a:hover {
    color: black;
}


.ant-tabs-nav .ant-tabs-tab {
  margin: 0 10px 0 0;
  color: $dark-text;
}

.ant-tabs-nav .ant-tabs-tab:hover {
  color: $theme-primary;
}

.ant-tabs-nav .ant-tabs-tab-active {
  font-weight: 600;
  color: $theme-primary;
}

.ant-tabs-nav .ant-tabs-ink-bar {
  background-color: $theme-primary
}

.ant-form-item-label > label::after {
  content: none;
}

.ant-tabs-bar {
  margin: 0;
}

.ant-menu-inline {
  width: 100.5%;
}

.ant-menu-vertical .settings__menu-item:after,
.ant-menu-vertical-left .settings__menu-item:after,
.ant-menu-vertical-right .settings__menu-item:after,
.ant-menu-inline .settings__menu-item:after {
  border-right: 4px solid rgba(73,208,212, 0.9);
}

// ANTD FORMS
.ant-btn-primary {
  background: $theme-primary;
  border-color: $theme-primary;

  &:focus {
    background: $theme-primary;
    border-color: $theme-primary;
  }

  &:hover {
    background: $default-focus-bg;
    border-color: #e4e4e4;
  }

  &.ant-btn-background-ghost {
    color: #c0c0c0;
    border-color: #c0c0c0;

    &:hover {
      color: $theme-primary;
      border-color: $theme-primary;
    }
  }
}

.ant-btn-text {
  border-color: transparent;
  background: 0 0;
  box-shadow: none;

  &:hover {
    color: $theme-primary;
    border-color: $theme-primary;
  }
}

.ant-form-item {
  margin-bottom: 0;

  label {
    float: left;
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background: rgba(0,0,0,.25);
  border-color: rgba(0,0,0,.25);
  color: rgba(250, 250, 250, 1);
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  left: 28%;
}

.ant-radio .ant-radio-inner {
  background: rgba(187, 174, 174, 0.25);
  border-color: rgba(151, 133, 133, 0.25);
  border-radius: 35%;
  &:hover {
    outline: none;
    box-shadow: none;
  }
}

.ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
  border-color: rgba(151, 133, 133, 0.25);
}
.ant-radio-input {
  border-color: rgba(255, 255, 255, 0.25);
  color: rgba(255, 255, 255, 0.25);
}

.ant-radio-checked {
  background: rgba(17, 16, 16, 0.6);
  color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
  border-radius: 35%;
}

.ant-radio-inner::after {
  background: rgba(255, 253, 253, 2.25);
  border-radius: 35%;
}

.ant-form {
  min-width: 300px;
}

.guest_card_button {
  &:hover {
    color: white;
    cursor: default;
  }
}

.ant-table-row .view-detail-link {
  color: $theme-primary;
  font-weight: 600;
}

.ant-table-row .view-detail-link:hover {
  color: $default-focus-bg;
}

.ant-tabs-nav-wrap {
  margin-left: 14px;
}

.ant-table-row a {
  text-decoration: underline;
}

.ant-table-pagination.ant-pagination {
  margin-right: 15px !important;
}

.ant-pagination-item, .ant-pagination-next {

  // ^^ next buttons on pagination aren't changing to RG blue...

  &:hover, &:focus {
    border-color: $theme-primary;

    & > a {
      color: $theme-primary;
    }
  }

  &-active {
    color: $theme-primary;
    border-color: $theme-primary;

    & > a {
      color: $theme-primary;
    }
  }
}
.ant-pagination-next:hover .ant-pagination-item-link {
  color: $theme-primary;
  border-color: $theme-primary;
}

.ant-pagination-item-link-icon {
  color: $theme-primary !important;
}

.ant-pagination-next:focus .ant-pagination-item-link {
  color: $theme-primary;
  border-color: $theme-primary;
}

.ant-table-thead > tr > th {
  color: inherit;
  font-weight: 600;
  font-size: 15px;
}

.ant-layout-footer {
  background: $theme-background;
}

.ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane,
.ant-tabs .ant-tabs-bottom-content > .ant-tabs-tabpane {
  padding-left: 2.5%;
  padding-right: 2.5%;
}

.ant-tabs-nav .ant-tabs-ink-bar {
  background: $theme-primary;
}

.ant-table-wrapper {
  overflow: scroll;
}

.anticon.ant-notification-notice-icon-info {
 color: $theme-primary;
}

.ant-notification {
  width: unset;
}

.ant-switch {
  background-color: #1890ff;
}

.ant-switch-checked {
  background-color: #45BDBC;
}

.ant-btn > .anticon + span, .ant-btn > span + .anticon {
  margin-left: 1px;
}

button.grey {
  background-color: #939393;
  border-color: #939393;
  color: $white;

  &:hover {
    background-color: #79797c;
    border-color: #79797c;
    color: $white;
  }

  &:active {
    background-color: #79797c;
    border-color: #79797c;
    color: $white;
  }
}

button.dark-red {
  background-color: #8d2403;
  color: $white;

  &:hover {
    background-color: #641902;
    color: $white;
    border-color: #641902;
  }

  &:active {
    background-color: #641902;
    color: $white;
    border-color: #641902;
  }
  &:focus {
    background-color: #641902;
    color: $white;
    border-color: #ff3c01;
  }
}

button.plain {
  background-color: transparent;
  color: $dark-text;
  border: none;

  &:hover {
    background-color: map-get($color, "background-gray");
    color: $dark-text;
    border: none;
  }

  &:active {
    background-color: map-get($color, "background-gray");
    color: $dark-text;
    border: none;
  }
}

// Global Media Queries
@media (max-width: $container-lg) {
  .ant-table-tbody td,
  .ant-table-thead th {
    padding: 16px 0px !important;
    padding-left: 16px !important;

    &:nth-child(even) {
      min-width: 180px;
    }

    &:nth-child(odd) {
      min-width: 100px;
    }
  }

  .ant-spin-container {
    position: relative;

    ul {
      position: absolute;
      display: flex;
    }
  }

  .ant-form {
    min-width: 100%;
  }
}
.ant-calendar-input  {
  margin: 0 !important;
}
