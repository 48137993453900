#dashboard-portal {
  position: relative;
}

.rootactionwindow {
  background: $theme-background;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  display: inline-table;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;

  &__modal {
    padding: 15px;
    &-header {
      padding: 10px;
      text-align: center;
    }

    &-body {
      text-align: center;
    }

    &-form {
      margin-top: 50px;
    }

    &-imagewrapper {
      max-width: 150px;
      max-height: 150px;
      margin: auto;
      margin-top: 75px;
      padding: 20px;
      &-image {
        max-width: 90%;
        max-height: 90%;
      }
    }

    &-logowrapper {
      max-width: 50px;
      max-height: 50px;
      padding: 5px;
      &-logo {
        max-width: 90%;
        max-height: 90%;
      }
    }
  }
}

.actionwindow {
  background: $theme-background;
  height: 100%;
  min-height: 100vh;
  width: 105%;
  display: inline-table;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  margin: -23px -28px 0 -28px;

  &__leads {
    top: -25px;
    left: -33px;
    width: calc(100vw - 200px)!important;
  }
}
