.messenger-qr {
  width: 400px;
  text-align: center;
  #qr-save {
    background-color: white;
    #header {
      margin-left: 60px;
      margin-right: 50px;
      margin-bottom: 20px;
      text-align: left;
    }
    p {
      font-size: 1.1rem;
    }
  }
}