.propertyDashboard {
  padding: 2.5% 0px;
  display: flex;

  @media (max-width: $container-md) {
    flex-direction: column;
  }

  &__col {
    width: 33%;

    @media (max-width: $container-xlg) {
      width: 49%;
    }

    @media (max-width: $container-md) {
      width: 100%;
    }
  }

  &__panel {
    &-widget {
      background-color: white;
      border-radius: 4px;
      margin: 20px 10px;
      -webkit-box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.1);

      &-header {
        text-align: center;
        padding: 10px;
        font-weight: 600;
        font-size: 14px;
      }

      &-subheader {
        font-weight: 400;
        font-size: 12px;
        color: $light-text;
      }

      &-cloudWrapper {
        padding: 0px 20px 20px 20px;

        #propertyDashboard__panel-widget-wordcloud {
          width: 100%;
          height: 190px;
        }
      }

      &-legend {
        text-align: center;
        display: inline-flex;
        padding: 0px 10px;
        align-content: center;

        &-color {
          height: 15px;
          width: 15px;
          margin: 0px 10px;
          border-radius: 2px;
          &.green {
            background-color: $light-green;
          }
          &.gray {
            background-color: #d3d3d3;
          }
        }

        &.align-right {
          float: right;
        }
      }

      &-bottom {
        height: 60px;
      }

      &-placeholder {
        width: 80%;
        margin: auto;
        padding: 0px;
        height: auto;
        max-height: 100%;
        & img {
          height: auto;
          max-width: 100%;
          max-height: 250px;
          display: block;
          margin: auto;
        }
      }
    }
  }
}

.proximityWords {
  &__exists {
    width: 100%;
    text-align: center;
    font-size: 18px;
    color: $light-gray;
    height: 30px;
  }
}

.sentimentList {
  padding-bottom: 25px;
  height: 280px;

  &__loading {
    font-size: 18px;
    color: $light-gray;
    text-align: center;
    padding: 30px;
  }

  &__row {
    align-items: center;

    &-pie {
      margin: 0 auto;
    }
    &-name {
      &-0 {
        font-size: 26px;
      }
      &-1 {
        font-size: 24px;
      }
      &-2 {
        font-size: 22px;
      }
      &-3 {
        font-size: 18px;
      }
      &-4 {
        font-size: 16px;
      }
      &-5 {
        font-size: 14px;
      }
      &-link,
      &-link:hover {
        text-decoration: underline;
        font-size: 18px;
      }
    }

    &-residents {
      margin: 5px 0px;
    }
  }

  &__residentList {
    height: 150px !important;
  }
}
