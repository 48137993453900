.property {
  &__top {
    background: $white;
    padding: 1em 2.5%;
    margin: -1.67em -2em 0 -2em;

    @media (max-width: $container-md) {
      padding: 1em 3.5%;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;

    @media (min-width: $container-md) {
      flex-direction: row;
    }

    &-right {
      width: 100%;
      h2 {
        font-weight: $font-weight-medium;

        @media (max-width: $container-md) {
          margin-top: 0.5em;
        }
      }

      &__labels {

        &-pending-activation {
          color: $dark-text;
          font-size: rem($base-font-size);
          padding: 0.2em 0.8em;
          border-radius: 2px;
          border: 1px solid $light-gray-grad;
          background: #f6ecc6;
        }

        &-no-data-source {
          color: $dark-text;
          font-size: rem($base-font-size);
          padding: 0.2em 0.8em;
          border-radius: 2px;
          border: 1px solid $light-gray-grad;
          margin-left: 1em;
          background: #e6e6e6;
        }

        &-service-suspended {
          color: $dark-text;
          font-size: rem($base-font-size);
          padding: 0.2em 0.8em;
          border-radius: 2px;
          border: 1px solid $light-gray-grad;
          background: #f6c6c6;
        }
      }
    }

    &-left {
      margin-right: 2em;
    }

    &-content {
      display: flex;
      flex-direction: column;

      @media (min-width: $container-md) {
        flex-direction: row;
        width: 100%;
      }
    }

    &-special {
      color: #49d0d4;
      font-weight: bold;
    }

    &-copy {
      margin-top: 0.5em;
      width: 450px;

      @media (max-width: $container-md) {
        width: 100%;
      }
    }

    &-image {
      width: 150px;
      height: 150px;
      border-radius: 4px;
    }

    &-details {
      @media (min-width: $container-md) {
        width: 40%;
      }
    }

    &-non-active-progress {
      display: flex;
      justify-content: space-between;
      .ant-progress-line {
        width: calc(100% - 110px);
      }
      span {
        color: $progress-color;
      }
    }

    &-non-active-progress,
    &-non-active-text {
      width: 50%;

      @media (max-width: $container-md) {
        width: 100%;
      }
    }
  }

  &__header-tabs {
    margin-left: -28px;
    margin-right: -28px;
  }

  &__content {
    padding: 2em;
    background: $white;
    margin-top: 2em;
  }

  &__notfound {
    margin-top: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    i {
      font-size: 80px;
    }
  }

  &__switch-button {
    margin: 0 5px;
    background-color: rgb(223, 223, 223);
  }

  .ant-switch-checked {
    background-color: rgb(223, 223, 223);
  }

  &__residents {
    &__table {
      &-row:hover:not(.ant-table-expanded-row) > td {
        background-color: #f4f4f4; // Why is this not working <<?
        cursor: pointer;
      }
    }
  }

  &__badge {
    z-index: 2;
  }

  &__csv-uploader {
    background: $white;
    padding: 0.5em;
    margin: 1em;

    &--format {
      padding: 1em 0.5em;
      color: black;
      img {
        margin-top: 5px;
      }
    }
    &--preview {
      padding: 1em 0.5em;

      &-tableRow {
        background: $white !important;
      }
    }

    &--preview-content {
      display: block;
      min-height: 50px;
      background-color: lightgray;
      padding: 6px;

      p {
        font-size: 0.9em;
      }

      &.black {
        color: black;
      }
    }

    &--errors {
      color: red;
    }

    &--actions {
      padding: 1.7em 0.5em;

      p {
        margin-bottom: 5px;
      }

      .send-invites {
        background-color: lightgray;
      }

      .cancel-invites {
        margin-left: 29px;
        border-color: transparent;
        &:hover,
        &:active,
        &:focus {
          color: black;
        }
      }
    }

    &--text {
      padding: 0.5em;
      color: black;
      font-size: 14px;
    }
  }

  &__csv-drop {
    text-align: center;
    background: #efefef;

    display: block;
    width: 100%;
    height: 278px;
    border-radius: 8px;
    border-width: 1.5px;
    border-style: dotted;

    &-choose-file {
      color: #49d0d4;
      cursor: pointer;
    }
    p {
      padding: 5em;
    }
  }

  &__invite-success-msg {
    text-align: center;
    background: #fff;
    display: block;
    width: 100%;
    height: 278px;

    .success-msg-icon {
      margin-top: 30px;
      margin-bottom: 30px;
    }

    h2 {
      font-weight: 600;
      font-size: 20px;
    }
  }

  .uploaded-file-icon {
    margin-top: 30px;
  }
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: $white !important;
    cursor: default;
  }

  &__email-previewlabel {
    padding: 20px;
    &-body {
      color: #2d2d2d;
    }
    strong {
      font-weight: 800;
    }
  }

  &__email-previewclickme {
    margin-bottom: 30px;
    padding-left: 20px;
    padding-top: 5px;
    cursor: pointer;
  }

  &__email-preview {
    position: relative;
    max-height: 274px;
    height: 350px;
    overflow: hidden;
    max-width: 100%;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 20px;

    &:hover {
      cursor: -moz-zoom-in;
      cursor: -webkit-zoom-in;
      cursor: zoom-in;
    }

    .overlay {
      height: 270px;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .ant-table-tbody > tr > td:first-child b {
    @media (max-width: $container-lg) {
      width: 100%;
      display: block;
    }
  }

  .ant-badge-count,
  .ant-badge-dot,
  .ant-badge .ant-scroll-number-custom-component {
    opacity: 0.8;
  }

  &__manage-residents {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row;
    }
  }

  &__invite {
    &-choose {
      padding: 20px;
    }
    &-yardi,
    &-resman,
    &-entrata {
      padding: 20px;
      border-bottom: 2px solid #e3e3e3;
      margin-bottom: 20px;
      border-top: 2px solid #e3e3e3;

      .send-invites {
        margin-top: 10px;
        background-color: lightgray;
        &:hover {
          color: black;
          border-color: lightgray;
        }

        &.active {
          background: #49d0d4;
          border-color: #49d0d4;
          color: #fff;
        }
      }
      &-link {
        margin-top: 10px;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    &-realpage {
      padding: 20px;
      border-bottom: 2px solid #e3e3e3;
      margin-bottom: 20px;
      border-top: 2px solid #e3e3e3;

      .send-invites {
        margin-top: 10px;
        background-color: lightgray;
        &:hover {
          color: black;
          border-color: lightgray;
        }

        &.active {
          background: #49d0d4;
          border-color: #49d0d4;
          color: #fff;
        }
      }
      &-link {
        margin-top: 10px;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    &-csv-label {
      padding: 20px;
    }
    &-resident {
      font-family: "Source Sans Pro";
      &:enabled {
        background-image: linear-gradient(#49d0d4, #49d0d4) !important;
        color: white;
        &:hover,
        &:active,
        &:focus {
          color: white;
          border-color: #61dce0;
          background-image: linear-gradient(#61dce0, #49d0d4) !important;
          box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.1), 0 4px 4px rgba(0, 0, 0, 0.1);
        }
      }
      &-space-right {
        margin-top: 15px;
        margin-left: 20px;
      }
    }
  }

  &__search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2em;

    & > span {
      margin-top: 2em;

      .ant-radio-button-wrapper-checked {
        color: #49D0D4 !important;
        border-color: #49D0D4 !important;
        box-shadow: -1px 0 0 0 #49D0D4 !important;
      }

      .ant-radio-button-wrapper:hover, .ant-radio-button-wrapper-checked:hover {
        color: #49D0D4 !important;
      }
    }

    @media (max-width: $container-lg) {
      align-items: flex-end;

      &-elements {
        width: calc(100% - 200px);
      }
    }

    @media (max-width: $container-md) {
      flex-direction: column;
      align-items: flex-start;

      &-elements {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
      }

      & > span {
        margin-top: 2em;
      }
    }

    h4 {
      padding-top: 1.5em;
      margin-right: 1em;

      @media (max-width: $container-lg) {
        padding-top: 0em;
        margin-right: 0em;
      }
    }

    &-input {
      width: 400px;
      display: flex;

      .new_property_button {
        padding-top: 1em;
        margin-left: 15px;
      }

      @media (max-width: $container-lg) {
        width: calc(100% - 100px);
      }

      @media (max-width: $container-md) {
        width: 100%;
      }
    }

    &-elements {
      display: flex;
      align-items: flex-end;
    }

    @media (max-width: $container-xxlg) {
      flex-direction: column;
    }

    &__select {
      display: flex;
      align-items: center;

      @media (max-width: $container-md) {
        margin-top: 25px;
      }
      
      &__label {
        font-family: Source Sans Pro;
        font-size: 14px;
        line-height: 20px;
        margin-right: 20px;
        color: #595959;
      }

      &__select {
        width: 131px;
      }
    }
  }
}
