.users {
  padding: 24px;
  background: $white;
  min-height: 100vh;
  min-width: 100%;

  &__left {
    @media (min-width: $container-lg) {
      //width: 30%;
    }
  }

  &__right {
    padding-top: 3em;

    @media (min-width: $container-lg) {
      width: 20%;
      margin-left: 2em;
      padding-top: 1em;
    }
  }
}

.invite-user-icon {
  font-size: 25px;
  color: $theme-primary;
  float: right;
  position: relative;
  z-index: 99;
  margin-top: -30px;
  margin-bottom: 30px
}

.properties__selection {
  ul li {
    &.ant-select-dropdown-menu-item-active,
    &:hover {
      background: rgba(73, 208, 212, .4)
    }
  }

  .selection-header {
    color: map-get($color, "black");
    background: map-get($color, "white");
    border-bottom: 1px solid map-get($color, "rentgrata-gray");
    font-weight: 500;

    &:hover {
      background: none;
      cursor: auto;
    }
  }
}

.ant-select-selection__rendered {
  ul li.ant-select-selection__choice {
    background: map-get($color, "rentgrata-blue");
    color: map-get($color, "rentgrata-gray");
  }

  .ant-select-search--inline {
    height: 0;
    width: 100%;

    input {
      margin: 0;
      width: 100%;
    }

    .ant-select-search__field__mirror {
      margin: 0;
      padding: 0;
      width: 100%;
    }
  }
}
