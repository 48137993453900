.prospect-landing-page {
  &__header {
    padding-top: 24px;
    padding-right: 41px;
    background: $white;
    display: flex;
    justify-content: space-between;

    .ant-btn {
      @media(max-width: $container-md) {
        display: none;
      }
    }
  }

  &__logo {
    padding-left: 38px;

    img {
      width: 208px;
      height: 52px;
    }

    span {
      margin-left: 27px;
      text-align: left;
      font-size: 24px;
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 100px;

    @media (max-width: $container-xlg) {
      flex-direction: column;
      margin-top: 64px;
    }

    @media (max-width: $container-md) {
      margin-top: 32px;
    }

    h1 {
      font-weight: bold;

      @media (min-width: $container-md) {
        font-size: 38px;
      }
    }
  }

  &__prospect {
    margin-top: 60px;
    margin-left: 150px;
    margin-right: 110px;
    max-width: 600px;

    @media (max-width: $container-xlg) {
      max-width: none;
      margin-top: 0;
      margin-left: 42px;
      margin-right: 42px;
      margin-bottom: 48px;
    }

    @media (max-width: $container-md) {
      width: calc(100% - 48px);
      margin-left: 24px;
      margin-right: 24px;
      margin-bottom: 32px;
      padding-right: 12px;
    }

    a {
      text-decoration: underline;
    }
  }

  &__benefits {
    background-color: #1e4c4a;
    color: $white;
    border-radius: 20px;
    margin-right: 150px;
    padding-top: 27px;
    min-width: 530px;
    max-width: 650px;

    @media (max-width: $container-xlg) {
      max-width: none;
      margin-left: 42px;
      margin-right: 42px;
      margin-bottom: 102px;
    }

    @media(max-width: $container-md) {
      width: calc(100% - 24px);
      min-width: 0;
      margin-left: 12px;
      margin-right: 12px;
      padding-right: 12px;
    }

    h1 {
      color: $white;
    }

    h2 {
      color: $white;
      margin-top: 15px;
      margin-bottom: 15px;
    }

    &-heading {
      display: flex;
      flex-direction: row;
      text-align: left;

      img {
        width: 45px;
        margin-bottom: 14px;
        margin-left: 27px;
        margin-right: 27px;
        filter: invert(100%);

        @media(max-width: $container-md) {
          width: 32px;
          margin-left: 21px;
          margin-right: 21px;
        }
      }
    }

    &-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: left;
      margin-bottom: 18px;

      p {
        margin-bottom: 0;
        font-weight: bold;
      }

      img {
        width: 35px;
        height: 35px;
        margin-left: 36px;
        margin-right: 32px;
        filter: invert(100%);

        @media(max-width: $container-md) {
          width: 24px;
          height: 24px;
          margin-left: 24px;
          margin-right: 21px;
        }
      }
    }

    &-footer {
      margin-top: 16px;
      padding-left: 37px;

      @media(min-width: $container-md) {
        font-size: 24px !important;
      }
    }
  }

  p {
    @media (min-width: $container-md) {
      font-size: 26px;
    }
  }

  .ant-btn {
    border-radius: 5px;

    @media (min-width: $container-md) {
      width: 155px;
      height: 61px;
      font-size: 24px;
    }
  }
}
