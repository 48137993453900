.header {
  min-height: 65px;

  &__left {
    @media (min-width: $container-lg) {
      width: 60%;
      float: left;
    }
  }

  &__right {
    display: flex;
    height: 65px;
    padding: 0 1em;
    width: 100%;
    justify-content: flex-end;
    align-items: center;

    @media (min-width: $container-lg) {
    }

    &-profile {
      min-width: 120px;
      height: 100%;
      border: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background:none;

      p {
        color: $light-text;
      }

      &:hover {
        background: map-get($color, "rentgrata-gray");
      }
    }
  }

  &__activity {
    line-height: 3.0 !important;
    width: 100%;
    padding: 2px !important;
    @media (max-width: $container-sm) {
      width: 92%;
    }
    &-prospects {
      &-no-activity {
        text-align: center;
      }

      &-details {
        width: 100%;
        font-size: 14px;
        margin-top: 10px;
        margin-right: 0px;
        margin-left: 5px;
        margin-bottom: 5px;

        &-listing {
          font-size: 14px !important;
          width: 85px;
          overflow: hidden;
          position: relative;
          display: inline-block;
          vertical-align: middle;
          text-align: center;
          margin: 0 5px 0 5px;
          text-decoration: none;
          text-overflow: ellipsis;
          white-space: nowrap;
          border-radius: 5px;
          border: 1px solid rgb(242, 242, 242);
          padding-top: 2px;
          padding-left: 2px;
          padding-right: 1px;
          padding-bottom: 2px;
          color: rgb(77, 77, 77) !important;
          background-color: rgb(242, 242, 242) !important;
          @media (max-width: $container-sm) {
            width: 25%;
          }
        }

        &-name {
          color: rgb(77, 77, 77) !important;
          text-decoration: underline;

          &-link {
            color: rgb(77, 77, 77) !important;
            text-decoration: underline;
          }
        }

        &-phrase {
          color: rgb(153, 153, 153) !important;
        }

        &-date {
          margin-left: 5px;
          color: rgb(153, 153, 153) !important;
          display: inline-block;
          position: relative;
          vertical-align: middle;
          @media (max-width: $container-sm) {
            margin-top: 5px !important;
          }
        }

        &-source {
          color: rgb(153, 153, 153) !important;
          width: 80px;
          overflow: hidden;
          position: relative;
          display: inline-block;
          vertical-align: middle;
          margin: 0 5px 0 5px;
          text-decoration: none;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 12px !important;
          @media (max-width: $container-sm) {
            margin-top: 5px !important;
          }
        }

        &-status {
          font-size: 14px !important;
          width: 80px;
          overflow: hidden;
          position: relative;
          display: inline-block;
          vertical-align: middle;
          text-align: center;
          text-decoration: none;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding: 2px 1px 2px 2px !important;
          border-radius: 5px;
          margin: 0 5px 0 5px;     
          padding-top: 2px;
          padding-left: 2px;
          padding-right: 1px;
          padding-bottom: 2px;
          
          &.green {
            border: 1px solid #bbe8bb!important;
            color: #095409 !important;
            background-color: #bbe8bb!important;
          }
          
          &.rgblue {
            border: 1px solid #abd6d5 !important;
            color: #0a4741 !important;
            background-color: #abd6d5 !important;
          }

          &.gray {
            border: 1px solid rgb(242, 242, 242);
            color: white !important;
            background-color: rgb(242, 242, 242) !important;
          }

          &.yellow {
            border: 1px solid #fff4d4 !important;
            color: #56560b !important;
            background-color: #fff4d4 !important;
          }

          &.red {
            border: 1px solid #f8b6a6 !important;
            color: #5e110f !important;
            background-color: #f8b6a6 !important;
          }

        }
      }
    }
  }

  &__see-more-row {
    margin-top: 20px;
    text-align: center !important;

    &-button {
      color: rgb(102, 102, 102);
      font-size: 15px;

      &:hover {
        color: rgb(31, 31, 31) !important;
      }

      &:focus {
        color: rgb(102, 102, 102);
      }

      span {
        text-decoration: underline;
      }

    }
  }

  &__search {
    z-index: 10px;
    &-text {
      display: none;
      @media (min-width: $container-lg) {
        display: block;
        padding-right: 0.5em;
        min-width: 100px;
      }
    }

    &-img {
      width: 50px;
      height: 50px;
      margin-right: 1em;
    }

    &-icons {
      display: flex;
      justify-content: space-between;
      align-items: center;

      i {
        margin: 0 0.5em;
        cursor: pointer;

        svg {
          height: 20px;
          width: 20px;
        }
      }
      span {
        margin: 0 1em;
      }
    }

    &-notifications {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 0.5em;
      border: none !important;

      i {
        cursor: pointer;

        svg {
          height: 20px;
          width: 20px;
        }
      }
      span {
        margin: 0 1em;
      }
    }

    &-input {
      position: relative;

      &-mobile-search-icon {
        display: none;

        @media (max-width: $container-lg) {
          display: inline-block;

          &:active {
            color: $default-focus-bg;
            cursor: pointer;
          }

          &:hover {
            color: $default-focus-bg;
            cursor: pointer;
          }
        }
      }

      #header__prospect-search {
        min-width: 350px !important;
        margin-top: 0;
        background-color: transparent;
      }

      @media (max-width: $container-lg) {
        position: fixed;
        width: 100%;
        background-color: $theme-default-background;
        box-shadow: 1px 1px 1px rgba(0, 0, 1, 0.1);
        top: 65px;
        left: 0;
        padding: 0px 10px;
        z-index: 1;
        display: none;

        &.__open {
          display: block;
        }

        #header__prospect-search {
          min-width: auto !important;
          border-color: $dark-gray;
        }

        .ant-btn-primary {
          i {
            svg {
              display: none;
            }
          }

          &::before {
            content: "";
          }

          &::after {
            content: "Search"
          }
        }
      }
    }


    &-result {
      display: none;
      flex-direction: column;
      padding: 7px;
      box-shadow: 3px 5px 8px rgba(0, 0, 0, 0.2);
      position: absolute;
      top: 48px;
      width: 100%;
      background: map-get($color, "white");
      line-height: 25px;
      z-index: 2;

      @media (max-width: $container-lg) {
        width: calc(100% - 20px);
      }

      .error__message {
        color: map-get($color, "red")
      }

      .usernames__wrapper {
        font-weight: bold;
        list-style-type: none;
        margin: 0;
        padding: 0;
        color: #000;
        li {
          text-indent: 0;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }

      .result__display {
        font-weight: 550;
        text-decoration: underline;
        text-indent: 1em;
        cursor: pointer;
        display: none;

        &.is-visible {
          display: flex;
        }
      }
    }
  }
}

.header_dropdownoverlay {
  top: 64px !important;

  .header__dropdownmenu {
    height: 100px;
    align-items: center;
    width: 130px;
    border-radius: 0;
    box-shadow: 3px 5px 8px rgba(0, 0, 0, 0.15);
    li {
      width: 100%;
      font-weight: 500;
      color: "dark-gray";
      display: inline-block;
      &:hover {
        background: rgba(250, 250, 250, 1);
        color: black;
      }
    }
  }
}

.header__helppopver {
  width: 200px;
}
