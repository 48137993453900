.sidebar {
  position: relative;
  background: $white;
  z-index: 100;

  @media (max-width: $container-lg) {
    position: fixed;
    top: 0px;
    height: 100vh;
  }

  &__logo {
    box-shadow: 2px 2px 2px rgba(0, 0, 1, 0.1);
    min-height: 63px;
    text-align: center;
    padding-top: 1em;
    margin-bottom: 1.5em;
    cursor: pointer;
    border: none;
    background-color: transparent;

    span {
      font-size: 1.7em;
      user-select: none;
    }

    img {
      width: 15%;
      margin-top: -5px;
      margin-right: 5px;

      @media (max-width: $container-lg) {
        visibility: hidden;
      }
    }
  }

  &__collapse {
    position: absolute;
    top: 0px;
    background-color: $white;
    width: 73px;
    height: 60px;

    @media (min-width: $container-lg) {
      visibility: hidden;
    }

    &-logo {
      position: absolute;
      top: 20px;
      width: 30px;
      left: 10px;
    }
  }

  .__not-intersecting {
    border-bottom-right-radius: 10px;
    border-color: transparent;
    -webkit-box-shadow: 2px 2px 2px rgba(0, 0, 1, 0.1);
    -moz-box-shadow: 2px 2px 2px rgba(0, 0, 1, 0.1);
    box-shadow: 2px 2px 2px rgba(0, 0, 1, 0.1);
  }
}

.sidebar__menu {
  &.ant-menu {
    &:not(.ant-menu-horizontal) {
      // primary menu
      .ant-menu-item {
        min-height: 48px;
        height: auto;
        &:after {
          border-left: 5px solid #107b7f;
          border-right: none;
          left: 0;
          right: auto;
        }
        &.ant-menu-item-selected {
          background-color: #e4f0f1;
          color: #46505c;
          font-weight: 400;

          a {
            color: #46505c;
          }
        }
      }
      // submenu styles
      .ant-menu-submenu {
        .ant-menu-item {
          line-height: 40px;
          height: 40px;
        }
        & > .ant-menu-submenu-title:hover {
          color: #000000;
          .ant-menu-submenu-arrow {
            &::before,
            &::after {
              background: -webkit-gradient(
                linear,
                left top,
                right top,
                from(#000000),
                to(#000000)
              );
              background: linear-gradient(to right, #000000, #000000);
            }
          }
        }
        // submenu individual menu item styles
        .ant-menu-sub {
          .anticon {
            display: none; // removes the icon support from the submenus in the sidebar menu
          }
        }
      }
    }
  }
  .sidebar__menu-item:after {
    border-left: 5px solid #606060;
    border-right: none;
    left: 0;
    right: auto;
  }
}

.sidebar__menu-item-content {
  position: relative;

  &-label,
  &-highlight {
    display: inline-block;
  }

  &-caption {
    margin-top: 4px;
  }

  &-highlight {
    button {
      display: flex;
      padding: 0.13rem 0.38rem 0.13rem 0.38rem;
      align-items: center;
      gap: 0.25rem;
      border-radius: 16px;
      border: 1px solid #9BEFF3;
      color: #156F71;
      background: #EAFEFF;
      font-size: 0.75rem;
      text-align: center;
      font-style: normal;
      font-weight: 500;
      position: absolute;
      cursor: pointer;
      right: 0;
      top: 8px;
      height: 22px;
    }
  }

  &-caption {
    display: block;
    text-wrap: wrap;
    white-space: normal;
    line-height: 14px;
    padding: 2px 0;
  }
}

.settings__menu.ant-menu:not(.ant-menu-horizontal)
  .ant-menu-item-selected {
  background-color: rgba(146, 255, 255, 0.2);
  color: $theme-primary;

  a {
    color: $theme-primary;
  }
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: none !important;
}

.ant-layout-sider-children > ul {
  @media (max-width: $container-lg) {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.ant-layout-sider-zero-width-trigger {
  @media (max-width: $container-lg) {
    top: 12px;
    left: 0;
    width: 65px;
    color: $dark-text;
    background: transparent;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &:hover {
      background: transparent;
    }
  }
}
.topic-guide-link {
  position: absolute;
  bottom: 0px;
}

.topic-guide--open {
  background-color: rgb(60, 60, 60);
  color: white !important;
}

.topic-guide-menu-item--open {
  a {
    color: white !important;
    font-size: 15px !important;
  }
}
