.prospects {
  padding-top: 2em;

  &__top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: $container-md) {
      flex-direction: column;
    }
  }

  &__card {
    background: $white;
    height: 200px;
    width: 24%;
    margin-bottom: 2em;
    padding: 2em;
    color: rgba(0,0,0,.5);
    box-shadow: 0 3px 3px -3px rgba(0,0,0,.5);

    @media (max-width: $container-xlg) {
      width: 48%;
    }

    @media (max-width: $container-md) {
      width: 100%;
    }

    &:last-child,
    &:nth-child(2) {
      margin-right: 0; 
    }

    &-experience {
      padding: 0px 7px;
    }

    &-positives {
      background: $light-green;
    }
    &-neutrals {
      background: $dark-gray;
    }
    &-negatives {
      background: $button-danger;
    }

    &-title {
      padding: 3px 10px;
      font-weight: 600;
      &-sa {
        padding: 5px 10px;
        color: $white;
        display: inline-flex;
      }
    }

    &-bignumber {
      font-size: 100px;
      font-weight: bold;
      padding: 5px;
      color: $light-green;
    }

    &-bignumber-zero {
      color: $red !important;
    }

    &-sidetext {
      font-size: 15px;
      margin-left: 15px;
    }

    &-info-icon {
      display: inline-flex;
      float: right;
      padding: 10px;
      color: $white;
      cursor: help;
      text-shadow: 0px 1px 2px rgba(40,40,40,0.2);

      &-content-panel {
        width: 250px;
      }
    }

    li {
      color: white;
    }

    h3 {
      color: rgba(0,0,0,.4);
      text-shadow: 0px 1px 2px rgba(40,40,40,0.2);
      font-size: 1em;
    }

    &-entityname {
      color: $white;
      cursor: help;

    }
    &-entityunavailable {
      color: $white;
      padding: 20px;
    }
  }

  &__table {
    background: $white;
    padding: 1.5em;

    &-row:hover:not(.ant-table-expanded-row) > td {
      background-color: $white !important;
    }
  }

  &__search {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 5em;
    margin-bottom: 2em;

    @media (max-width: $container-xlg) {
      flex-direction: column;
      align-items: flex-start;
      margin-right: 0em;
      margin-bottom: 1em;
      width: 48%;
    }

    @media (max-width: $container-md) {
      margin-right: 0em;
      margin-bottom: 1em;
      width: 100%;
    }

    &-info-icon {
      margin-left: 10px;
      margin-top: 17px;

      &-content-panel {
        width: 200px;
        height: 250px;
      }
    }

    &-section {
      display: flex;
      
      @media (max-width: $container-xlg) {
        justify-content: space-between;
      }

      @media (max-width: $container-md) {
        flex-direction: column;
      }
    }

    &-input {
      min-width: 300px;

      @media (max-width: $container-xlg) {
        min-width: 100%;
      }
    }

    &-image {
      max-width: 200px;
    }

    h4 {
      padding-top: 1.5em;
      margin-right: 1em;
    }

    &-switch {
      padding-top: 1.5em;
    }
  }
}

.prospects__card {
  padding: 0.5em;
}

.new_analytics_count {
  font-size: 32px;
  margin-left: 10px;
  margin-bottom: 0px;
  padding: 5px;
}

.new_analytics_count-prosp {
  font-size: 32px;
  margin-left: 10px;
  margin-bottom: 0px;
  padding: 20px;
}

.last_30_days {
  font-size: 12px;
  padding-left: 5px;
}
