.properties {

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2em;

    h3 {
      margin-top: 1em;
    }

    @media (max-width: $container-md) {
      flex-direction: column;

      h3 {
        margin: 0;
      }
    }
  }

  &__image {
    width: 75px;
    height: 75px;
    border-radius: 2px;
  }

  &__name {
    margin: 0.5em 0;
  }

  &__table {
    padding: 24px;
    background: $white;
    min-height: 100vh;
    min-width: 100%;
    background: white;

    td:first-child {
      width: 1px;
    }

    & tr:hover:not(.ant-table-expanded-row)> td {
      background-color: #f4f4f4;
      cursor: pointer;
    }
  }
}

.last-30-days {
  font-size: 10px;
}
