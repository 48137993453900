.reports {
  padding: 42px 42px 42px 26px;
  background: $white;
  min-height: calc(100vh - 158px);
  min-width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: $container-lg) {
    padding: 26px;
  }

  &__content {
    flex: 1;
    display: flex;

    @media (max-width: $container-xlg) {
      flex-direction: column;
    }
  }
}

.report-description {
  padding: 30px 0px 39px 38px;
  flex: 1;

  @media (max-width: $container-lg) {
    padding: 30px 0;
  }

  &__title {
    font: normal normal 600 28px/36px Source Sans Pro;
    letter-spacing: 0px;
    color: #141414;

    @media (max-width: $container-lg) {
      font: normal normal 600 22px/28px Source Sans Pro;
    }
  }

  &__description {
    text-align: left;
    font: normal normal normal 18px/23px Source Sans Pro;
    letter-spacing: 0px;
    color: #242424;

    p, li {
      color: #242424 !important;
    }
  }
}

.report-list {
  display: flex;
  flex-direction: column;
  width: 223px;
  border-right: 1px solid #bcbcbc;

  @media (max-width: $container-xlg) {
    border-right: none;
    border-bottom: 1px solid #bcbcbc;
    padding-bottom: 29px;
    width: 100%;
  }

  &__label {
    font: normal normal normal 14px/18px Source Sans Pro;
    letter-spacing: 0px;
    color: #363636;
    margin-bottom: 12px;
    margin-top: 27px;
  }

  &__item {
    font: normal normal 600 17px/22px Source Sans Pro;
    letter-spacing: 0px;
    color: #1e4c4a;
    padding: 19px 29px;

    &--selected {
      background-color: #eaffff;
    }
  }
}

.report-table {
  margin-top: 23px;

  &__header {
    background-color: #f0f0f0;
    border-radius: 10px 10px 0px 0px;
    padding: 14px 17px;
    display: flex;
    align-items: center;
    position: relative;

    @media (max-width: $container-xxxlg) {
      display: grid;
      grid-template-rows: auto auto;
      grid-template-columns: 1fr 1fr;
      grid-gap: 24px;
      padding-bottom: 20px;
    }

    @media (max-width: $container-md) {
      display: flex;
      flex-direction: column;
    }
  }

  &__table {
    width: calc(100vw - 550px);
    overflow: hidden;

    @media (max-width: $container-xlg) {
      width: 100%;
    }

    .ant-table-wrapper {
      overflow: unset;
      overflow-x: scroll;
    }

    .ant-table-thead tr {
      background-color: #d6d6d6;
    }

    .ant-table-thead th {
      padding: 5px 21px;
      font: normal normal 600 14px/18px Source Sans Pro;
      letter-spacing: 0px;
      color: #242424;
    }

    .ant-table-tbody td {
      font: normal normal normal 14px/18px Source Sans Pro;
      letter-spacing: 0px;
      color: #242424;
      padding-top: 21px;
      padding-bottom: 21px;
    }

    .ant-table-tbody {
      tr {
        border-bottom-color: #D9D9D9;
      }
      tr:nth-child(even) {
        background: #ffffff;
      }
      tr:nth-child(odd) {
        background: #F8F8F8;
      }
    }
  }

  &__no-data,
  .ant-table-placeholder  {
    height: 500px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font: normal normal 400 28px/36px Source Sans Pro;
    letter-spacing: 0px;
    color: #646464;
    z-index: 0;

    @media (max-width: $container-xlg) {
      font: normal normal 400 22px/28px Source Sans Pro;
    }
  }

  &__footer {
    background-color: #f0f0f0;
    border-radius: 0px 0px 10px 10px;
    padding: 14px 17px;
    display: flex;
    justify-content: flex-end;
  }
}

.report-input-field {
  display: inline-flex;
  align-items: center;
  margin-right: 44px;

  @media (max-width: 1690px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  @media (max-width: $container-xxxlg) {
    margin-right: 0px;
    margin-bottom: 0px;
  }

  @media (max-width: $container-xlg) {
    width: 100%;
  }

  @media (max-width: $container-md) {
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 0px;
    margin-bottom: 0px;
  }

  &:last-of-type {
    margin-right: 0;
  }

  .ant-input {
    border: none;
    z-index: 1;
    background-color: transparent;
  }

  .ant-select {
    width: 100%;
    z-index: 1;
  }

  .ant-select-selection {
    background-color: transparent;
    border: none;
  }

  .ant-calendar-picker-icon,
  .anticon-close-circle,
  .ant-select-arrow {
    visibility: hidden;
  }

  &__label {
    margin-right: 22px;
    font: normal normal normal 16px/20px Source Sans Pro;
    letter-spacing: 0px;
    color: #141414;

    @media (max-width: $container-xxxlg) {
      margin-right: 10px;
      margin-bottom: 0px;
    }
  }

  &__input {
    border: 1px solid #bcbcbc;
    border-radius: 5px;
    position: relative;
    background-color: #fff;

    &__button {
      width: 47px;
      height: 33px;
      background-color: #1e4c4a;
      border-radius: 0px 5px 5px 0px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 0px;
      top: -1px;
      bottom: -1px;
      border-left: 1px solid #bcbcbc;
      cursor: pointer;
    }
  }
}

.report-table__footer .report-input-field {
  min-width: 180px;
}

.report-button {
  background-color: #1e4c4a;
  border: 1px solid #707070;
  border-radius: 5px;
  width: 110px;
  height: 33px;
  position: absolute;
  right: 17px;

  &.ant-btn.ant-btn-loading {
    position: absolute;
  }

  @media (max-width: 1690px) {
    bottom: 14px;
  }

  @media (max-width: $container-xxxlg) {
    width: 100%;
    bottom: -9px;
    right: 0px;
    position: relative;

    &.ant-btn.ant-btn-loading {
      position: relative;
    }
  }

  @media (max-width: $container-md) {
    width: 100%;
    position: unset;
    right: 0;

    &.ant-btn.ant-btn-loading {
      position: unset;
    }
  }

  &:hover,
  &:active {
    background-color: #163735;
    cursor: pointer;
  }
}
