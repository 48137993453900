.billing-action-window {
  position: absolute;
  width: calc(100vw - 200px);
  margin: -1.67em -2em 0 -2em;
  background-color: red;
  z-index: 11;

  @media (max-width: $container-lg) {
    width: 100vw;
  }

  .billing {
    &__top-wrapper {
      padding: 1em 2.5% 2.5%;
    }

    &__top {
      background: $white;

      @media (max-width: $container-md) {
        padding: 1em 3.5%;
      }

      button {
        padding: 0 2rem;
      }
    }

    &__header {
      display: flex;
      flex-direction: column;

      @media (min-width: $container-md) {
        flex-direction: row;
      }

      &-right {
        width: 100%;

        span {
          font-size: $h4-font-size;
          font-weight: $font-weight-medium;
        }

        h2 {
          font-weight: $font-weight-medium;
          font-size: $h3-font-size;
          margin-bottom: 0.4rem;

          @media (max-width: $container-md) {
            margin-top: 0.5em;
          }
        }

        p {
          margin: 0;
        }
      }

      &-left {
        margin-right: 2rem;
      }

      &-image {
        width: 120px;
        height: 120px;
        border-radius: 4px;
      }
    }

    &__content {
      padding: 1.5rem 2.5rem;
      background: #e8e8e8;
      width: 100%;
      height: 100vh;

      @media (max-width: $container-md) {
        padding: 1.5rem 0 0;
      }
    }
  }

  button[type=text] {
    background: unset;
    border: unset;
    text-decoration: underline;
  }

  button[type=text]:hover {
    cursor: pointer;
  }
}

.update_payment__card {
  background: $white;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  padding: 2rem 2.5rem 10rem 2.5rem;

  &-cards-wrapper {
    width: 70%;
    margin-bottom: 1.8rem;
    margin-top: 0.2rem;

    .payment-method {
      padding: 1.4rem 1.5rem;
    }

    @media (max-width: $container-lg) {
      width: 100%;
    }
  }

  h3 {
    font-weight: $font-weight-medium;
    font-size: $h3-font-size;
    color: $light-text;
    margin-top: 2rem;
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0.5rem;
  }

  button.dark-btn {
    background-color: $light-text;
    border-color: $light-text;
    color: $white;
  }
}

.add_payment {
  background: $white;
  padding: 1.7rem 0 3.5rem 0;
  text-align: center;

  &--header {
    position: relative;

    @media (max-width: $container-xlg) {
      width: 90%;
      margin: auto;
      display: flex;
      flex-direction: column;
    }

    &-cta {
      position: absolute;
      left: 2rem;
      text-decoration: underline;
      font-size: rem(11);

      @media (max-width: $container-xlg) {
        margin-bottom: 5px;
        position: relative;
        display: block;
        text-align: left;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  h3 {
    font-weight: $font-weight-medium;
    font-size: $h3-font-size;
  }

  h4 {
    text-align: left;
    color: $light-text;
  }

  &--info-wrapper {
    width: 100%;
    display: flex;
    margin-top: 3.5rem;

    @media (max-width: $container-xlg) {
      flex-direction: column;
    }

    &.payment-container-Stripe-ACH {
      margin: auto;
      margin-top: 1rem;
      width: 95%;
      min-height: 40rem;
      background-color: #f6f8f9;
      position: relative;
    }

    &.payment-container-Stripe, &.payment-container-Invoice {
      margin: auto;
      margin-top: 3rem;
      padding: 3em;
      width: 95%;
      min-height: 30rem;
      background-color: #f6f8f9;
      position: relative;
    }
  }

  &--payment-info {
    width: 50%;
    padding: 2rem 0 6rem 0;
    text-align: center;
    margin-left: 2rem;

    @media (max-width: $container-xlg) {
      width: 100%;

      &:first-child {
        border-right: none;
      }
    }

    &:first-child {
      border-right: 2px solid #bbbbbb;

      @media (max-width: $container-xlg) {
        border-right: none;
      }
    }

    h4 {
      font-weight: $font-weight-medium;
      font-size: $h3-font-size;
    }

    span {
      display: block;
      width: 100%;
    }

    &-payment-percentage {
      font-weight: $font-weight-medium;
      margin-top: 2rem;
      font-size: $h3-font-size;
      color: $dark-text;
    }

    &-payment-desc {
      font-weight: $font-weight-medium;
      margin-bottom: 2.5rem;

      &.bank-ach {
        margin-bottom: 0;
      }
    }

    &-details {
      text-align: center;
      width: 55%;
      margin: auto;
      margin-bottom: 2.5rem;
      font-size: rem(12);

      &.bank-ach {
        width: 90%;
        margin-bottom: 0.5rem;
      }
    }

    &--payment-powered {
      color: $dark-text;
      font-weight: $font-weight-medium;
      font-size: rem(10);
      margin-top: 0.5rem;
    }
  }

  &--payment-ach-details {
    display: flex;
    margin-top: 1.5rem;
    padding: 0 1rem;

    @media (max-width: $container-sm) {
      flex-direction: column;

      &-right {
        margin-top: 2.5rem;
      }
    }

    &-title {
      font-weight: $font-weight-medium;
      color: $black;
      margin-bottom: 0.5rem;
    }
  }

  &--pay-by-invoice {
    padding-top: 3em;
  }

  &--payment-info--footer {
    margin-top: 2rem;
    color: $dark-text;

    a {
      color: #4ca6a7;
      text-decoration: underline;
    }
  }

  &--payment-info--powered {
    color: $dark-text;
    font-weight: $font-weight-medium;
    font-size: rem(11);
    height: fit-content;
    position: absolute;
    right: 1rem;
    bottom: 1rem;
  }
}

.payment_history {
  background: $white;
  width: 100%;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  display: flex;

  @media (max-width: $container-md) {
    flex-direction: column;
  }

  h3 {
    color: $light-text;
    font-size: $h4-font-size;
  }

  &--time {
    background: #edeff5;
    border-right: 2px solid #bbbbbb;
    padding: 0.8rem 1.2rem;
    width: 12rem;

    @media (max-width: $container-md) {
      width: 100%;
    }

    ul {
      display: block;
      list-style-type: none;
      padding-inline-start: 1rem;

      li {
        &:hover,
        &.active {
          cursor: pointer;
          text-decoration: underline;
          color: $dark-text;
          list-style-type: disc;
        }
      }
    }
  }

  &--table {
    background: $white;
    padding: 0.8rem 1.2rem;
    width: calc(100% - 15rem);

    @media (max-width: $container-md) {
      width: 100%;
    }

    .ant-table-body {
      margin-top: 0.5rem;
      margin-bottom: 5rem;

      td {
        padding: 0.625rem 1rem;
      }

      th {
        border-right: 2px solid #e6e6e6;
        border-bottom: 2px solid #e6e6e6;
        background-color: $white;
        padding: 0.5rem 0.8rem;

        &:last-child {
          border-right: none;
        }
      }
    }

    .table__cta-wrapper {
      display: flex;

      &-status {
        width: 4.2rem;
        background: #5cb67b;
        color: $white;
        text-align: center;
        border-radius: 5px;
        border: 2px solid #8ab395;
        padding: 0.001rem 0;
        height: fit-content;

        &.unpaid {
          background-color: #ebebeb;
          color: #8c2510;
          border: 2px solid #8c2510;
        }
      }

      &-pdf-cta {
        margin-left: 3rem;
        color: #58d2d8;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
}

.ach-confirmation {
  width: 100%;
  height: 50%;
  background: $white;
  padding: 1.7rem 20% 3.5rem 20%;
  text-align: center;
}

.remove_payment {
  width: 100%;
  height: 100%;
  background: $white;

  &__content {
    width: 60%;
    padding: 3rem;

    @media (max-width: $container-xlg) {
      width: 100%;
    }

    &-grey-text {
      color: $dark-gray;
    }

    &-description-text {
      margin: 1rem 0;
    }

    &-payment-details {
      color: $black;
      margin-left: 1rem;
      font-size: $h4-font-size;

      @media (max-width: $container-xlg) {
        margin-left: 0;
      }
    }

    &-payment-type {
      color: $light-text;
      text-transform: uppercase;
      margin-left: 2rem;
      font-weight: $font-weight-medium;

      @media (max-width: $container-xlg) {
        display: block;
        width: 100%;
        text-align: left;
        margin-left: 0;
      }
    }

    &-cta-wrappers {
      display: flex;
      justify-content: flex-end;

      button:last-child {
        margin-left: 0.5rem;
      }
    }
  }
}

.verify_bank_account {
  width: 100%;
  height: 100%;
  background: $white;
}

.ant-modal-content {
  .cancel {
    background: none !important;
    border: none;
    border-radius: 0;
    padding: 0 !important;
    color: #4d4d4d;
    border-bottom: 1px solid #4d4d4d;
    cursor: pointer;
    margin-right: 1em;
  }
}

.micro-deposit-form {
  &__errors {
    width: 100%;
    margin-top: 1rem;
    color: $light-text;
    font-weight: $font-weight-medium;
    color: $indian-red;
    text-align: center;
  }
}
