.pdf-document {
  width: 100%;
  height: 30rem;
  border: none;
}

.pdf-mobile {
  width: 100%;
  height: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    padding: 0 2rem;
  }
}
