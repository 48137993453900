.preview {

  &__container {
    width: 50%;
    text-align: center;
    margin: auto;
  }

  &__header {
    margin-top: 10%;
    color: #666
  }

  &__logo {
    margin-top: 5%;
  }

  &__amount-earned {
    color: #48D1A2;
    font-size: 60px;
    font-weight: bold;
    margin: 20px 0
  }

  &__text-1 {
    color: #666;
  }
  
  &__text-2 {
    font-weight: normal;
    color: #666
  }

  &__update-profile {
    font-weight: bold;
    width: 100%;
    height: 50px;
    letter-spacing: normal;
    line-height: 100%;
    text-align: center;
    margin: auto;
    font-size: 15px;
    text-decoration: none;
    color: #FFFFFF;
    margin-top: 50px;
    padding-top: 10px;
  }

  &__social-media {
    display: 'inline';
    margin: 0 15px;
  }

  &__social-media-buttons {
    margin: 100px 25px 50px 20px;
  }

  &__social-media-image {
    width: 25px;
    height: 25px;
  }

  &__footer-text {
    margin-bottom: '100px';
    font-family: 'source sans pro, helvetica neue, helvetica, arial, sans-serif';
    color: #666
  }

  &__copyright-text {
    color: #808080;
  }

  &__yelp-button {
    background-color: #D42423;
    font-weight: bold;
    width: 100%;
    height: 50px;
    letter-spacing: normal;
    line-height: 100%;
    text-align: center;
    margin: auto;
    font-size: 15px;
    text-decoration: none;
    color: #FFFFFF;
    margin-top: 50px;
    padding-top: 10px;
    border-radius: 10px;
  }

  &__yelp-button:hover {
    cursor: pointer;
    background-color: #EA5B39;
  }

  &__google-button {
    background-color: #34A853;
    font-weight: bold;
    width: 100%;
    height: 50px;
    letter-spacing: normal;
    line-height: 100%;
    text-align: center;
    margin: auto;
    font-size: 15px;
    text-decoration: none;
    color: #FFFFFF;
    margin-top: 50px;
    padding-top: 10px;
    border-radius: 10px;
  }

  &__google-button:hover {
    cursor: pointer;
    background-color: #34A853;
  }

  &__facebook-button {
    background-color: #3A80EB;
    font-weight: bold;
    width: 100%;
    height: 50px;
    letter-spacing: normal;
    line-height: 100%;
    text-align: center;
    margin: auto;
    font-size: 15px;
    text-decoration: none;
    color: #FFFFFF;
    margin-top: 50px;
    padding-top: 10px;
    border-radius: 10px;
  }
    
  &__facebook-button:hover {
    cursor: pointer;
    background-color: #3A80EB;
  }
}
