.prospect {
  &__analysis {
    background: $white;
    margin: 1em 2em 0 0;
    padding: 2em;
    width: 100%;

    @media (min-width: $container-lg) {
      width: 50%;
    }

    #prospecttable th {
      border-bottom: 1px solid #ddd;
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left;
    }

    &-pos {
      color: $theme-primary;
      border-right: 1px solid #ddd;
    }

    &-lcontent {
      border-right: 1px solid #ddd;
    }

    &-neg {
      color: $red;
    }
  }

  &__top {
    background: $white;
    padding: 1em;
    margin: -21px -28px 0 -28px;
  }

  &__header {
    padding: 0px 20px;
  }

  &__bottom {
    margin-top: 1em;
    padding: 2em;
    background: $white;
  }

  &__status {
    background:$white;
    display: flex;
    padding-top: 1em;
    margin-top: 1em;
    justify-content: space-between;

    @media (max-width: $container-md) {
      width: 100%;
      overflow-x: auto;
    }

    &-left {
      width: 25%;
      text-align: center;
      border-right: 1px solid #ddd;

      @media (max-width: $container-md) {
        width: 40%;
      }

      &-denied-lost {
        color: #c2c2c2 !important;
        margin-top: 5px;

        @media (max-width: $container-md) {
          padding: 0em 1em;
        }
      }
    }

    &-right {
      width: 85%;

      &-steps {
        margin-top: 6px;
      }
    }
  }

  &__state {
    justify-content: flex-end;
    display: flex;

    @media (max-width: $container-md) {
      margin-top: 1em;
    }

    &-inprogress {
      padding-right: 2em;

      @media (max-width: $container-md) {
        padding-right: 0em;
      }
    }

    &:first-child {
      color: $button-warning;
      border-color: $button-warning;
    }

    &-applied {
      color: $light-text;
      border-color: $light-text !important;
      box-shadow: none;

      &:before {
        background-color: $light-text !important;
      }

      &:hover {
        color: $button-warning;
        border-color: $button-warning !important;
      }
    }

    &-active {
      color: $light-text;
      border-color: $light-text !important;
      box-shadow: none;

      &:before {
        background-color: $light-text !important;
      }

      &:hover {
        color: $button-warning;
        border-color: $button-warning !important;
      }
    }

    &-lease_signed {
      color: $light-text;
      border-color: $light-text !important;
      box-shadow: none;

      &:before {
        background-color: $light-text !important;
      }

      &:hover {
        color: $button-warning;
        border-color: $button-warning !important;
      }
    }

    &-moved_in {
      color: $white;
      border-top-left-radius: 0.3em  !important;
      border-bottom-left-radius: 0.3em  !important;
      border-color: $antd-button-primary !important;
      background: $antd-button-primary;
      margin-left: 3em;

      @media (max-width: $container-md) {
        margin-left: .8em;
      }

      @media (max-width: $container-xsm) {
        margin-left: 0em;
        margin-top: .8em;
      }

      &:hover {
        color: $white;
        border-color: $antd-button-primary;
        background: $antd-button-primary;
      }
    }

    &-denied_or_lost {
      color: $light-text;
      border-top-right-radius: 0.3em;
      border-bottom-right-radius: 0.3em;
      border-color: $light-text !important;
      box-shadow: none;

      &:hover {
        color: $button-danger;
        border-color: $button-danger !important;
        box-shadow: none;
      }
    }
  }

  &__guestcard {
    background: $white;
    padding: 2em;
    width: 100%;

    &-left {
      margin: 1em 0 0 0;

      @media (max-width: $container-xlg) {
        width: 70%;
      }

      @media (max-width: $container-lg) {
        width: 100%;
      }

      @media (max-width: $container-md) {
        display: flex;
        flex-direction: column;
      }
    }

    &-right {
      margin: 1em 0 0em 1em;

      @media (max-width: $container-xxlg) {
        width: 30%;
      }

      @media (max-width: $container-xlg) {
        width: calc(30% - 1em);
      }

      @media (max-width: $container-lg) {
        width: 100%;
        margin: 1em 0 0em 0em;
      }
    }

    &-cta {
      background:none!important;
      border:none;
      padding:0!important;
      margin-left: 1em;
      color: $antd-button-primary;
      border-bottom:1px solid $antd-button-primary;
      cursor: pointer;
    }

    &-user-info {
      margin-bottom: 12px;
      display: flex;

      @media (max-width: $container-md) {
        width: 100%;
        flex-direction: column;
      }
    }

    &-name-date {
      display: block;
      padding-top: 5px;
      width: 50%;
      box-sizing: border-box;

      @media (max-width: $container-md) {
        width: 100%;
      }
    }

    &-move-in-form {
      display: block;
      width: 50%;
      box-sizing: border-box;

      @media (max-width: $container-md) {
        width: 100%;
      }
      
      label {
        padding-right: 10px;
        font-weight: 900;
        color: $light-text;
      }

      button {
        margin-top: 10px;
      }

      .status-form-wrapper__body-move-in-date {
        display: flex;
      }

      .status-form-wrapper__body-lease-term {
        display: flex;

        .ant-input {
          width: 30%;
          margin: 0 5%;
        }
      }

      .status-form-wrapper__body-unit-number {
        display: flex;

        .ant-input {
          width: 50%;
        }
      }
    }

    &-request {
      margin-bottom: 1em;

      .ant-input-affix-wrapper {
        width: 70%;
        max-width: 400px;
      }

      &:first-child {
        width: 70%;
      }
    }

    &-lock {
      color: $theme-primary;
      margin-left: 1em;
    }

    &-unlock-date {
      font-weight: 400;
      color: #c7c7c7;
    }

    &-unlock {
      color: $theme-primary;
      margin: 0 .3em 0 1em;
    }

    &-header {
      display: flex;
      text-align: center;
      justify-content: space-between;
      width: 50%;
      margin-bottom: 1em;

      @media (max-width: $container-md) {
        width: 100%;
      }

      i {
        padding-top: 0.3em;
      }
    }
  }

  &__extra-info {
    display: flex;
    flex-direction: column;
    background: transparent;

    @media (min-width: $container-lg) {
      flex-direction: row;
    }
  }

  &__denied {
    background: $white;
  }

  &__header {
    display: flex;
    flex-direction: column;
    padding: 10px 20px;

    &-top {
      display: flex;
      justify-content: space-between;

      @media (max-width: $container-md) {
        flex-direction: column;
        align-items: baseline;
      }
    }

    &-bio {
      display: flex;

    }

    &-name {
      padding: 5px 10px;
    }

    &-content {
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      @media (min-width: $container-md) {
        flex-direction: row;
        width: 100%;
      }

      @media (min-width: $container-lg) {
        width: 65%;
      }
    }

    &-copy {
      width: 400px;
    }

    &-image {
      width: 100px;
      height: 100px;
      border-radius: 50px;
    }

    &-details {
      @media (min-width: $container-md) {
        width: 50%;
      }
    }
  }

  &__table {
    background: $white;
    padding: 1em;

    &-row:hover:not(.ant-table-expanded-row) > td {
      background-color: $white !important;
    }
  }

  &__modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-body {
      padding: 1em 0;
    }
  
    &--unschedule-reward {
      position: absolute;
      left: 0%;
      color: red;
    }
  }

  &__timeline, &__add-comments {
    background-color: white;
    margin-top: 15px;
    padding: 15px;
  }

  &__add-comments {
    .cancel-button {
      margin-left: 5px;
    }
  }

  &__timeline {
    .activities-list {
      background-color: #f2f2f2;
      margin-top: 15px;
      padding: 15px;
      overflow-y: scroll;
      max-height: 300px;
      font-size: small;

      .spin {
        margin-left: 50%;
      }

      .activities {
        display: flex;
        flex-direction: row;
        font-size: smaller;

        .datetime {
          min-width: 10%;
        }
  
        .text {
          width: 80%;
        }
  
        .comment-content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 80%;
          
          &-text {
            min-width: 18%;
          }

          &-body {
            display: flex;
            flex-direction: column;
            width: 80%;
          
            .text {
              padding: 10px;
              background-color: white;
              border-radius: 5px;
              min-height: 50px;
              width: 80%;
              border: 1px solid #b1b1b1;
            }
            
            .buttons {
              display: flex;
              justify-content: flex-end;
              width: 80%;

              .ant-btn {
                background-color: unset;
                border: unset;
                font-size: smaller;
                padding: 0 10px;
                height: 20px;

                &:hover {
                  font-weight: bold;
                  color: unset;
                }
              }
            }
          }
        }
      }

      .ant-timeline-item {
        width: 100%;
        padding: 0 0 10px;
      }

      .ant-timeline-item-head {
        background-color: #525252 !important;
        width: 6px;
        height: 6px;
      }
    
      .ant-timeline-item-tail {
        background-color: #525252;
        left: 2px;
        border-left: 2px solid #b1b1b1 !important;
        top: unset;
      }

      .ant-timeline-item-content {
        font-size: 14px;
      }

      .ant-divider-horizontal {
        margin: 10px 0 0 -25px;
      }

      .ant-divider {
        background-color: #e1e1e1;
      }
    }
  }

  &__message-topics {
    span {
      color: rgb(8, 5, 5);
      font-size: 12px;
    }

    a {
      text-decoration: underline;
    }

    &--container {
      display: flex;
      flex-direction: column;
      background: transparent;
      max-height: 168px;

      @media (min-width: $container-lg) {
        flex-direction: row;
      }
    }

    &--how-to {
      width: 50%;

      .content {
        background: lightgray;
        color: black;
        padding: 1em;
        border-radius: 1em;
        font-size: smaller;

        p {
          font-size: 11px;
         }
      }

      .header {
        font-weight: bold;
        font-size: 14px;
      }
    }

    &--topics {
      width: 100%;
      overflow: scroll;
      ul {
        font-weight:bolder;
        // font-size: 35px;
        list-style-type: none;
        padding: 0;
        // color: rgb(42, 75, 74)
      }

      .top-entity {
        color: #1E4C4A;
        font-size: 36px;
      }

      .bottom-entity {
        color: #777777;
        font-size: 26px;
      }
    }
    &--no-topics {
      width: 100%;
      p {
        padding-top: 40px;
        color: black;
        font-size: 24px;
      }
    }
  }

  #prospecttable {
    border-collapse: collapse;
    width: 100%;
  }

  #prospecttable td, #prospecttable th {
    padding: 8px;
  }
}

.prospect__state-denied::before {
  background-color: $light-text !important
}


.incoming-status-change {
  &__header {
    font-weight: bold;
    margin: 0 0 15px 0;
    font-size: 18px;
    margin-left: 2%;
    margin-bottom: 5%;
  }

  &__header-col-l {
    text-align: center;
    margin-bottom: 10px;
  }

  &__header-col-r {
    text-align: center;
    margin-bottom: 10px;
  }

  &__current-col {
    text-align: center !important;
    @media (max-width: $container-sm) {
      margin-left: 5px;
      margin-top: -15px;
      width: 88% !important;
    }
  }

  &__incoming-col {
    text-align: center !important;
    @media (max-width: $container-sm) {
      margin-top: -20px !important;
      margin-left: 5px;
      width: 88% !important;
    }
  }

  &__status {
    display: block;
    margin-bottom: 10px !important;
    font-size: 18px;
    font-weight: bold;
    width: 100%;
    font-size: 18px;

    &-wrapper-current {
      margin-left: 5px !important;
    }

    &-current {
      border: 1px solid #d5d5d5;
      box-shadow: 0px 1px 3px #c2c2c2;
      font-weight: 500 !important;
      border-radius: 5px;
      display: block;
      background-color: rgb(230, 230, 230);
      padding: 5px;
      font-size: 16px;
      text-align: left !important;
      color: rgb(5, 5, 5);
      padding: 10px 0px 10px 10px;

      &-name {
        font-size: 21px !important;
        font-weight: 510 !important;
        margin-bottom: 10px !important;
      }

    }

    &-incoming-pink {
      border: 1px solid #f8b6a6 !important;
      box-shadow: 0px 1px 3px #c2c2c2;
      font-weight: 550 !important;
      border-radius: 5px;
      display: block;    
      padding: 5px;
      font-size: 26px;
      color: #5e110f !important;
      padding: 35px 25px 35px 25px;
      background-color: #f8b6a6 !important;
    }

    &-incoming-rgblue {
      border: 1px solid #abd6d5 !important;
      box-shadow: 0px 1px 3px #c2c2c2;
      font-weight: 550 !important;
      border-radius: 5px;
      display: block;    
      padding: 5px;
      font-size: 26px;
      color: #0a4741 !important;
      padding: 35px 25px 35px 25px;
      background-color: #abd6d5 !important;
    }

    &-incoming-green {
      border: 1px solid #bbe8bb!important;
      box-shadow: 0px 1px 3px #c2c2c2;
      font-weight: 550 !important;
      border-radius: 5px;
      display: block;    
      padding: 5px;
      font-size: 26px;
      color: #095409 !important;
      padding: 35px 25px 35px 25px;
      background-color: #bbe8bb!important;
    }

    &-incoming-yellow {
      border: 1px solid #fff4d4 !important;
      box-shadow: 0px 1px 3px #c2c2c2;
      font-weight: 550 !important;
      border-radius: 5px;
      display: block;    
      padding: 5px;
      font-size: 26px;
      color: #56560b !important;
      padding: 35px 25px 35px 25px;
      background-color: #fff4d4 !important;
    }

  }

  &__ignore-btn {
    background-color: #b3b3b3 !important;
    color: white;
  }

  &__status-header {
    color: black;
    font-size: 18px;
  }

  &__arrow-right-col {
    text-align: left;
    margin-top: 75px;

    &-arrow {
      font-size: 35px !important;
      margin-left: -10px;
      margin-right: 10px;
      display: inline;
    }

  }
  
  &__deactivate-reward {
    margin-top: 20px;
    margin-bottom: 55px;

    &-language {
      margin-bottom: 5px;
      padding: 0px 5px 0px 5px;
    }
    
    &-options {
      padding: 0px 5px 0px 5px;
    }

  }

  &__deactivate-reward-checkbox {
    margin-top: 15px;
    text-align: left;
  }

  &__reward-table {
    margin-top: 15px;
  }

  &__move-in-form-wrapper {
    margin-left: 1% !important;
  }

  &__instructions {
    color: rgb(145, 145, 145);
    margin-left: 1% !important;
    font-size: 15px !important;
    margin-bottom: 15px !important;
  }

  &__confirmation-text {
    color: rgb(2, 2, 2);
    margin-left: 0.5% !important;
    margin-top: 10px !important;
    line-height: 2.0 !important;
    font-size: 1.2rem !important;
  }

  &__action-taken-error {
    color: red;
    text-align: right;
    left: 0;
    bottom: 0;
    padding-top: 15px;
  }

}

.ant-drawer-body {
  overflow-y: scroll !important;
  height: 120% !important;
}

.prospect,
.actionwindow {
  .ant-steps-item-finish,
  .ant-steps-item-wait {
    .ant-steps-item-title {
      color: $light-gray !important;
    }

    .ant-steps-item-tail::after,
    .ant-steps-icon-dot {
      background: $light-gray !important;
    }
  }

  .ant-steps-item-process {
    .ant-steps-item-title {
      font-weight: bold;
      color: $dark-gray !important;
    }

    .ant-steps-item-tail::after {
      background: $light-gray !important;
    }

    &.lead {
      .ant-steps-icon-dot {
        background: $light-green !important;
      }
    }

    &.active {
      .ant-steps-icon-dot {
        background: $light-yellow !important;
      }
    }

    &.applied {
      .ant-steps-icon-dot {
        background: $light-yellow !important;
      }
    }

    &.leasesigned {
      .ant-steps-icon-dot {
        background: $light-yellow !important;
      }
    }

    &.movedin {
      .ant-steps-icon-dot {
        background: $theme-primary !important;
      }
    }
  }

  .ant-steps-item-wait,
  .ant-steps-item-process {
    .ant-steps-item-tail::after {
      background: transparent !important;
    }
  }

  .ant-steps-item-icon {
    margin-left: 45px !important;
  }

  .ant-steps-item-tail {
    margin: 0 0 0 47px !important;
  }

  .ant-steps-item-content {
    width: 100px !important;
  }
}
